import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import OrderItem from '../OrderItem';
import DetalhesCompra from '../../components/modals/DetalhesCompra';

class ComprasTab extends PureComponent {
  state = {
    isModalOpen: false,
  };

  componentDidMount() {
    this.props.getOrders();
  }

  changeModalState = () => {
    this.setState(prevState => ({ isModalOpen: !prevState.isModalOpen }));
  };

  selectOrder = id => {
    this.setState({ isModalOpen: true }, () => this.props.selectOrder(id));
  };

  render() {
    const { orders, loadingOrders, loadingOrder, loading } = this.props;
    const { isModalOpen } = this.state;
    return (
      <div className="panel">
        {loadingOrders || loading ? (
          <PulseLoader
            sizeUnit={'px'}
            size={15}
            color={'#ffffff'}
            loading={loadingOrder}
          />
        ) : orders.length ? (
          orders.map(order => (
            <OrderItem
              key={`list-activations-${order.id}`}
              callback={this.selectOrder}
              {...order}
            />
          ))
        ) : (
          <p>Nenhum registro encontrado.</p>
        )}
        {isModalOpen && (
          <DetalhesCompra
            loading={loadingOrder}
            changeModalState={this.changeModalState}
          />
        )}
      </div>
    );
  }
}

const mapState = state => ({
  orders: state.orders.list,
  loadingOrders:
    state.loading.effects.orders.getOrdersAsync ||
    state.loading.effects.orders.refreshOrders,
  loadingOrder: state.loading.effects.orders.getOrderAsync,
});

const mapDispatch = dispatch => ({
  getOrders: () => dispatch.orders.getOrdersAsync(),
  refreshOrders: filters => dispatch.orders.refreshOrders(filters),
  selectOrder: id => dispatch.orders.getOrderAsync(id),
});

export default connect(
  mapState,
  mapDispatch,
)(ComprasTab);
