import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ActivationItem from '../ActivationItem';
import { PulseLoader } from 'react-spinners';
import DetalhesAtivacao from '../modals/DetalhesAtivacao';

class ActivationsTab extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };
  }
  componentDidMount() {
    this.props.getActivations();
  }

  changeModalState = () => {
    this.setState(prevState => ({ isModalOpen: !prevState.isModalOpen }));
  };

  selectActivation = id => {
    this.setState({ isModalOpen: true }, () => this.props.getActivation(id));
  };

  render() {
    const {
      activations,
      loading,
      loadingActivation,
      loadingActivations,
    } = this.props;
    const { isModalOpen } = this.state;

    return (
      <div className="panel">
        {loadingActivations ? (
          <PulseLoader
            sizeUnit={'px'}
            size={15}
            color={'#ffffff'}
            loading={loading}
          />
        ) : activations.length ? (
          activations.map(activation => (
            <ActivationItem
              key={`list-activations-${activation.id}`}
              callback={this.selectActivation}
              {...activation}
            />
          ))
        ) : (
          <p>Nenhum registro encontrado.</p>
        )}
        {isModalOpen && (
          <DetalhesAtivacao
            loading={loadingActivation}
            changeModalState={this.changeModalState}
          />
        )}
      </div>
    );
  }
}

const mapState = state => ({
  activations: state.activations.list,
  loading: state.loading.models.activations,
});

const mapDispatch = dispatch => ({
  getActivations: () => dispatch.activations.getActivationsAsync(),
  refreshActivations: filters =>
    dispatch.activations.refreshActivations(filters),
  getActivation: id => dispatch.activations.getActivationAcync(id),
});

export default connect(
  mapState,
  mapDispatch,
)(ActivationsTab);
