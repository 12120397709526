import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ButtonLoading from '../../components/ButtonLoading';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import ErrorsMessage from '../../components/ErrorsMessage';
import { ModalConfirm } from './Confirm';
import { formatLicensePlate } from '../../utils/formatters';

const ParkVehicle = Yup.object().shape({
  licensePlate: Yup.string().required('Favor informar a PLACA'),
  parkingTime: Yup.number().required('Favor informar um TEMPO de permanência'),
});

class EstacionarVeiculo extends PureComponent {
  state = {
    labelParkingTime: '',
    modalVisible: false,
  };

  handleModalState = () => {
    this.setState(prevState => ({ modalVisible: !prevState.modalVisible }));
  };

  handleTimeToPark = (time, setFieldValue) => {
    const labelPark =
      time / 60 < 1
        ? time / 60 + ' minutos'
        : time / 60 > 1
        ? time / 60 + ' horas'
        : time / 60 + ' hora';
    this.setState({ labelParkingTime: labelPark });
    setFieldValue('parkingTime', time, true);
  };

  submit = () => {
    const {
      park,
      cancel,
      city: { products, key },
      vehicleType,
      getPossibleActivations,
      saveTickedId,
      saveLicensePlate,
    } = this.props;
    const { licensePlate, origin, parkingTime } = this.state;
    var rotativo = null;
    var quantity = parkingTime / 60;
    if (key === 'belohorizonte') quantity = null;
    if (key === 'lafaiete') quantity = parkingTime / 60 / 2;

    this.setState({ modalVisible: false }, () => {
      getPossibleActivations(licensePlate, quantity)
        .then(response => {
          if (vehicleType === 1)
            rotativo = response.tickets.find(
              item => item.id === products.carro,
            );
          else if (vehicleType === 2)
            rotativo = response.tickets.find(item => item.id === products.moto);

          if (
            (rotativo.hasOwnProperty('ticket') && rotativo.ticket === null) ||
            (rotativo.hasOwnProperty('tickets') && rotativo.tickets === null)
          )
            toast.error('Você não tem saldo suficiente para estacionar!');
          else {
            const ticket = rotativo.hasOwnProperty('ticket')
              ? rotativo.ticket
              : rotativo.hasOwnProperty('tickets') && rotativo.tickets;
            saveLicensePlate(licensePlate);
            saveTickedId(ticket);
            park({
              parkingTime,
              origin,
              licensePlate: licensePlate
                .replace(/[^a-zA-Z0-9]/g, '')
                .toUpperCase(),
            })
              .then(() => cancel())
              .catch(err => err);
          }
        })
        .catch(err => err);
    });
  };

  render() {
    const { modalVisible, labelParkingTime } = this.state;
    const {
      licensePlate,
      cancel,
      loading,
      city: { parkingTime },
    } = this.props;
    return (
      <div className="modal">
        <div className="modal-content">
          <i className="fas fa-window-close" onClick={cancel} />
          <h3>Estacionar veículo</h3>
          <Formik
            initialValues={{
              licensePlate: licensePlate,
              origin: 'WEB',
              parkingTime: '',
            }}
            validationSchema={ParkVehicle}
            onSubmit={values => {
              this.setState({
                ...values,
                modalVisible: true,
              });
            }}
          >
            {({ errors, touched, setFieldValue }) => (
              <Form>
                <div className="form-group">
                  <div className="placa">
                    <span>placa</span>
                    <span>{formatLicensePlate(licensePlate)}</span>
                  </div>
                  <div className="estacionar">
                    <p>Selecione um tempo de permanência:</p>
                    <div className="radios">
                      {parkingTime.carro.map(time => (
                        <div className="radio">
                          <input
                            type="radio"
                            id={
                              time / 60 < 1
                                ? time / 60 + ' minutos'
                                : time / 60 > 1
                                ? time / 60 + ' horas'
                                : time / 60 + ' hora'
                            }
                            name="tempo"
                            onClick={ev =>
                              this.handleTimeToPark(time, setFieldValue)
                            }
                          />
                          <label
                            htmlFor={
                              time / 60 < 1
                                ? time / 60 + ' minutos'
                                : time / 60 > 1
                                ? time / 60 + ' horas'
                                : time / 60 + ' hora'
                            }
                          >
                            <i className="fas fa-clock" />
                            Máximo{' '}
                            {time / 60 < 1
                              ? time / 60 + ' minutos'
                              : time / 60 > 1
                              ? time / 60 + ' horas'
                              : time / 60 + ' hora'}
                          </label>
                        </div>
                      ))}
                    </div>
                    {errors.parkingTime && touched.parkingTime && (
                      <ErrorsMessage
                        style={{
                          position: 'relative',
                        }}
                        message={errors.parkingTime}
                      />
                    )}
                  </div>
                </div>
                <br />

                <ButtonLoading
                  type="submit"
                  loading={loading}
                  text={`ESTACIONAR ${labelParkingTime} `}
                  color="#64853D"
                />
              </Form>
            )}
          </Formik>
        </div>
        {modalVisible && (
          <ModalConfirm
            cancel={this.handleModalState}
            confirm={this.submit}
            text={`Tem certeza que deseja estacionar o veículo com a placa ${formatLicensePlate(
              licensePlate,
            )} por ${labelParkingTime} ?`}
          />
        )}
      </div>
    );
  }
}

const mapState = state => ({
  loading: state.loading.effects.activations.parkAsync,
  loadingPossibleActivations:
    state.loading.effects.activations.getPossibleActivationsAsync,
  user: state.user,
  city: state.city,
});

const mapDispatch = dispatch => ({
  park: payload => dispatch.activations.parkAsync(payload),
  getPossibleActivations: (licensePlate, quantity) =>
    dispatch.activations.getPossibleActivationsAsync({
      licensePlate,
      quantity,
    }),
  saveTickedId: ticketId => dispatch.activations.saveTickedId(ticketId),
  saveLicensePlate: licensePlate =>
    dispatch.activations.saveLicensePlate(licensePlate),
});

export default connect(
  mapState,
  mapDispatch,
)(EstacionarVeiculo);
