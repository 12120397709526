import { getOrders, getOrder, deleteOrder } from '../repositories';

const initialState = {
  list: [],
  selectedOrder: {},
};

export const orders = {
  state: initialState,
  reducers: {
    saveOrdersHistory(state, payload) {
      return {
        ...state,
        list: [...payload],
      };
    },
    selectOrder(state, payload) {
      return {
        ...state,
        selectedOrder: payload,
      };
    },
    clearStore() {
      return initialState;
    },
  },
  effects: dispatch => ({
    async getOrdersAsync(payload, rootState) {
      if (rootState.orders.length > 0) {
        return Promise.resolve();
      }
      try {
        const response = await getOrders(0, 20);
        dispatch.orders.saveOrdersHistory(response.data);
      } catch (err) {}
    },
    async refreshOrders(payload, rootState) {
      try {
        const response = await getOrders(0, 20, payload);
        dispatch.orders.saveOrdersHistory(response.data);
      } catch (err) {}
    },
    async getOrderAsync(payload, rootState) {
      try {
        const response = await getOrder(payload);
        dispatch.orders.selectOrder(response.data);
      } catch (err) {}
    },
    async deleteOrderAsync({ id, value }, rootState) {
      try {
        const response = await deleteOrder(id, value);
        return response.data;
      } catch (e) {
        throw e;
      }
    },
  }),
};
