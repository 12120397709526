import React, { PureComponent } from 'react';
import VehicleCard from '../VehicleCard';
import { PulseLoader } from 'react-spinners';
import './style.css';

class VehiclesBox extends PureComponent {
  render() {
    const { vehicles, loading, changeModalState, parkChat, city } = this.props;
    return (
      <div className="col col-1">
        <div className="box">
          <h3>Meus veículos</h3>
          <div className="vehicles-container">
            {loading ? (
              <PulseLoader
                sizeUnit={'px'}
                size={20}
                color={'#ffffff'}
                loading={loading}
              />
            ) : vehicles.length ? (
              vehicles.map(vehicle => (
                <VehicleCard
                  key={`vehicle-${vehicle.licensePlate}`}
                  item={vehicle}
                />
              ))
            ) : (
              <p style={{ color: 'white' }}>Não há veículos encontrados.</p>
            )}
          </div>
          <div className="flex-row">
            <button onClick={changeModalState} className="add">
              NOVO VEÍCULO
            </button>
            {city.name === 'Itabira' && (
              <button onClick={parkChat} className="btn-estacionar">
                ESTACIONAR
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default VehiclesBox;
