import React, { Component } from 'react';
import { connect } from 'react-redux';
import VehiclesBox from '../../components/VehiclesBox';
import AccountBox from '../../components/AccountBox';
import { addAuthToken } from '../../repositories';
import AdicionarVeiculo from '../modal/AdicionarVeiculo';
import PaymentType from '../modal/PaymentType';
import BuyCredit from '../modal/FormaPagamento';
import ActivationsTab from '../../components/ActivationsTab';
import ComprasTab from '../../components/ComprasTab';
import FormaPagamentoReais from '../modal/FormaPagamentoReais';
import BaixeAplicativo from '../modal/BaixeAplicativo';
import { BlipChat } from 'blip-chat-widget';

var client = new BlipChat()
  .withAppKey(
    'aXRhYmlyYWF0aXZhY2FvOjU5NGM5NzRkLWQ4YzgtNDBhZi04NmM4LTM4NjViZjIyMWYxOA==',
  )
  .withButton({
    color: '#2CC3D5',
    icon:
      'https://s3-sa-east-1.amazonaws.com/msging.net/Services/Images/a788e21b-4a1f-41a2-82d5-61e36d15d9e7',
  })
  .withEventHandler(BlipChat.LOAD_EVENT, function() {
    var iframe = document.getElementById('blip-chat-iframe');
    iframe.contentWindow.postMessage(
      { code: 'ShowCloseButton', showCloseButton: true },
      iframe.src,
    );
  })
  .withEventHandler(BlipChat.CREATE_ACCOUNT_EVENT, function() {
    client.sendMessage({
      type: 'application/vnd.lime.chatstate+json',
      content: {
        state: 'starting',
      },
    });
  });

client.build();

class Home extends Component {
  constructor(props) {
    super(props);
    const { user, withPromo } = props;
    if (Object.keys(user).length && user.token) {
      addAuthToken(user.token);
    }
    this.state = {
      addVehicles: false,
      buyCredit: false,
      paymentSelected: false,
      promoCode: withPromo ? withPromo : null,
      showModalApp: false,
    };
  }

  changeVehiclesModalState = () => {
    this.setState(prevState => ({ addVehicles: !prevState.addVehicles }));
  };

  changeStoreModalState = () => {
    this.setState(prevState => ({ buyCredit: !prevState.buyCredit }));
  };

  changeAppModalState = () => {
    this.setState(prevState => ({ showModalApp: !prevState.showModalApp }));
  };

  changePaymentModalState = () => {
    this.setState(prevState => ({
      paymentSelected: !prevState.paymentSelected,
    }));
  };

  componentDidMount() {
    this.props.getVehicles();
    this.props.getCards();
  }

  componentWillMount() {
    if (this.state.promoCode && Object.keys(this.props.user).length > 0) {
      this.props.applyPromotion(this.state.promoCode).then(response => {
        if (response.status !== 200) this.setState({ showModalApp: false });
        else this.setState({ showModalApp: true });
      });
    }
  }

  openBlipChat() {
    client.widget._openChat();
  }

  render() {
    const {
      addVehicles,
      buyCredit,
      paymentSelected,
      showModalApp,
    } = this.state;
    const {
      vehicles,
      cards,
      city,
      order: {
        payment: { data: paymentType },
      },
      loadingVehicles,
      loadingOrders,
    } = this.props;

    return (
      <div>
        <div className="home">
          <VehiclesBox
            parkChat={this.openBlipChat}
            changeModalState={this.changeVehiclesModalState}
            vehicles={vehicles}
            city={city}
            loading={loadingVehicles}
          />
          <div className="col col-1">
            <div className="box">
              <h3>Minhas Compras</h3>
              <div className="tabs">
                <input
                  name="tabs1"
                  type="radio"
                  id="tab-2"
                  defaultChecked="checked"
                  className="input"
                />
                <label htmlFor="tab-2" className="label lb1">
                  Últimas 20
                </label>
                <ComprasTab loading={loadingOrders} />
              </div>
            </div>
          </div>
          <div className="col col-1">
            <div className="box">
              <h3>Minhas Ativações</h3>
              <div className="tabs">
                <input
                  name="tabs"
                  type="radio"
                  id="tab-1"
                  defaultChecked="checked"
                  className="input"
                />
                <label htmlFor="tab-1" className="label lb1">
                  Últimas 20
                </label>
                <ActivationsTab />
              </div>
            </div>
          </div>
          <div className="col col-2">
            <AccountBox
              cards={cards}
              city={city}
              changeModalState={this.changeStoreModalState}
            />
          </div>
        </div>

        {addVehicles && (
          <AdicionarVeiculo changeModalState={this.changeVehiclesModalState} />
        )}

        {showModalApp && (
          <BaixeAplicativo changeModalState={this.changeAppModalState} />
        )}
        {buyCredit &&
          (city.name !== 'Salvador' || city.name !== 'Igarapé' ? (
            <BuyCredit
              changeModalState={this.changeStoreModalState}
              changePaymentModalState={this.changePaymentModalState}
            />
          ) : (
            <FormaPagamentoReais
              changeModalState={this.changeStoreModalState}
              changePaymentModalState={this.changePaymentModalState}
            />
          ))}
        {paymentSelected && (
          <PaymentType
            paymentType={paymentType}
            changeModalState={this.changePaymentModalState}
          />
        )}
      </div>
    );
  }
}

const mapState = state => ({
  vehicles: state.vehicles,
  cards: state.cards,
  user: state.user,
  city: state.city,
  order: state.order,
  loadingVehicles:
    state.loading.effects.vehicles.getVehiclesAsync ||
    state.loading.effects.vehicles.refreshVehiclesAsync,
  loadingOrders: state.loading.effects.orders.refreshOrders,
});

const mapDispatch = dispatch => ({
  getVehicles: () => dispatch.vehicles.getVehiclesAsync(),
  getCards: () => dispatch.user.getUserAsync(),
  applyPromotion: payload =>
    dispatch.promotions.applyPromotionCodeAsync(payload),
});

export default connect(
  mapState,
  mapDispatch,
)(Home);
