import React from 'react';
import { compose } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import { MAPS_KEY, customMapStyle } from '../../utils/constants';

const LATITUDE = -19.95111;
const LONGITUDE = -43.92154;

const MapWithActivated = compose(
  withScriptjs,
  withGoogleMap,
)(props => {
  const { location } = props;
  const lat = location.latitude || LATITUDE;
  const lng = location.longitude || LONGITUDE;
  return (
    <GoogleMap
      defaultOptions={{ styles: customMapStyle, disableDefaultUI: true }}
      defaultZoom={16}
      defaultCenter={{
        lat,
        lng,
      }}
    >
      <Marker
        position={{
          lat,
          lng,
        }}
      />
    </GoogleMap>
  );
});

const ActivetedMap = props => {
  const { position } = props;
  return (
    <MapWithActivated
      location={position}
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ width: '100%', height: '100px' }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
};

export default ActivetedMap;
