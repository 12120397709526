import React, { PureComponent } from 'react';
import ButtonLoading from '../../components/ButtonLoading';

class RemoverVeiculo extends PureComponent {
  render() {
    const { licensePlate, confirm, cancel, loading } = this.props;
    return (
      <div className="modal">
        <div className="modal-content">
          <i class="fas fa-window-close" onClick={cancel} />
          <h3>Remover veículo</h3>
          <p>Confirma remoção do veículo abaixo ?</p>
          <div className="placa">
            <span>placa</span>
            <span>{licensePlate}</span>
          </div>
          <br />
          <ButtonLoading
            loading={loading}
            callback={confirm}
            text="Confirmar"
            color="#993b3c"
          />
        </div>
      </div>
    );
  }
}

export default RemoverVeiculo;
