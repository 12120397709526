// import { toast } from 'react-toastify';
import { addAuthDomain } from '../repositories';

const initialState = {
  key: '',
  name: null,
  credits: [],
};

export const city = {
  state: initialState,
  reducers: {
    changeCity(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    clearStore() {
      return initialState;
    },
  },
  effects: dispatch => ({
    changeDomain(payload) {
      addAuthDomain(payload.name);
      dispatch.city.changeCity(payload);
    },
    clearStores() {},
  }),
};
