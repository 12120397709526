import React, { PureComponent } from 'react';
import { Formik, Form, Field } from 'formik';
import { Link, navigate, redirectTo } from '@reach/router';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import { addAuthToken } from '../../repositories';
import { DOMAINS } from '../../utils/constants';
import * as Yup from 'yup';

import ErrorsMessage from '../../components/ErrorsMessage';
import Splash from '../../components/Splash';
import bg_urbes from './../../assets/images/bg-saae.jpg';
import { removeCharsDoc } from '../../utils/formatters';
import { toast } from 'react-toastify';

const SignupSchema = Yup.object().shape({
  doc: Yup.string().required('Favor informar o seu CPF/CNPJ'),
  password: Yup.string().required('Favor informar a sua senha'),
});

class LoginSAAE extends PureComponent {
  constructor(props) {
    super(props);
    const {
      user,
      location: { state },
    } = props;
    if (Object.keys(user).length && user.token) {
      addAuthToken(user.token);
      redirectTo('/');
    }
    this.state = {
      doc: state && state.doc ? state.doc : '',
    };
  }

  handleChangeCity = value => {
    const { changeDomain } = this.props;
    const domain = DOMAINS.filter(domain => domain.key === value)[0];
    if (domain) changeDomain(domain);
  };

  handleDoc = event => {
    if (removeCharsDoc(event.value).length >= 12)
      this.setState({ maskDoc: '99.999.999/9999-99' });
    else this.setState({ maskDoc: '999.999.999-999' });
  };

  render() {
    const { loading, city } = this.props;
    const { doc } = this.state;

    return (
      <div className="content-login-urbes">
        <div className="div-urbes">
          <img src={bg_urbes} alt="bg-urbes-img" />
        </div>
        {loading && <Splash />}
        <div className="form-login-urbes">
          <Formik
            initialValues={{
              doc,
              password: '',
              city: city ? city.key : 'belohorizonte',
            }}
            validationSchema={SignupSchema}
            onSubmit={values => {
              toast.error('Código de cliente ou senha incorretos');
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <h1>Acesse sua conta</h1>
                <br />
                <div className="hidden">
                  <Field
                    component="select"
                    name="city"
                    validate={this.handleChangeCity}
                  >
                    <option value="sorocaba">Sorocaba</option>
                  </Field>
                </div>
                <div className="form-group">
                  <Field
                    type="text"
                    name="doc"
                    placeholder="CÓDIGO DE CLIENTE"
                  />
                  {errors.doc && touched.doc ? (
                    <ErrorsMessage message={errors.doc} />
                  ) : null}
                </div>
                <div className="form-group">
                  <Field type="password" name="password" placeholder="SENHA" />
                  {errors.password && touched.password ? (
                    <ErrorsMessage message={errors.password} />
                  ) : null}
                </div>
                <button type="submit" className="btn btn-green">
                  Entrar
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  loading: state.loading.effects.user.signInAsync,
  user: state.user,
  city: state.city,
});

const mapDispatch = dispatch => ({
  login: payload => dispatch.user.signInAsync(payload),
  changeDomain: payload => dispatch.city.changeDomain(payload),
});

export default connect(
  mapState,
  mapDispatch,
)(LoginSAAE);
