import { formatTimestampToDateHours } from '../../../utils/formatters';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import ActivetedMap from '../../Map';
import './style.css';

class DetalhesAtivacao extends PureComponent {
  render() {
    const {
      loading,
      changeModalState,
      activation,
      activation: { latitude, longitude },
      city,
    } = this.props;
    const status = activation.giveUp ? 'CANCELADO' : 'OK';
    const stringDate = formatTimestampToDateHours(activation.transactionDate);
    const giveUpDate = activation.giveUp
      ? formatTimestampToDateHours(activation.giveUp.createdAt)
      : null;
    const giveUpToken = activation.giveUp ? activation.giveUp.receipt : null;
    const product = activation.product ? activation.product : {};
    const street = activation.street ? activation.street : {};

    return (
      <div className="modal">
        <div className="modal-content">
          <i className="fas fa-window-close" onClick={changeModalState} />
          <h3>Detalhe da ativação</h3>
          {loading ? (
            <ClipLoader
              sizeUnit={'px'}
              size={30}
              color="#ffffff"
              loading={loading}
            />
          ) : (
            <div className="container-ativacao">
              <p>Placa:</p>
              <p>{activation.licensePlate}</p>
              <p>Data da ativação:</p>
              <p>{stringDate}</p>
              <p>Tempo selecionado:</p>
              <p>{activation.parkingTime} minutos</p>
              <p>Tipo de ativação:</p>
              <p>{product.description}</p>
              <p>Localização:</p>
              <ActivetedMap position={{ latitude, longitude }} />

              {city && city.key === 'salvador' && (
                <div>
                  <p>Endereço:</p>
                  <p>{street.name}</p>
                  <p className="detalhe-endereco">{street.description}</p>
                  <p>Autenticação Transalvador:</p>
                  <p>{activation.receipt}</p>
                </div>
              )}
              <p>Origem:</p>
              <p>{activation.origin}</p>
              {city && city.key === 'salvador' && status === 'CANCELADO' && (
                <div>
                  <p>Cancelamento:</p>
                  <p className="item-cancelado">
                    {status} em {giveUpDate}
                  </p>
                  <p>Autenticação cancelamento:</p>
                  <p className="item-cancelado">{giveUpToken}</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  activation: state.activations.activationSelected,
  loading: state.loading.models.activations,
  city: state.city,
});

export default connect(mapState)(DetalhesAtivacao);
