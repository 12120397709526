import React from 'react';
import './style.css';
import { ClipLoader } from 'react-spinners';
import { formatNumberToCurrency } from '../../utils/formatters';

export const CardSaldo = ({ loading, title, value }) => (
  <div className="card-saldo">
    <p>{title}</p>
    {loading ? (
      <ClipLoader sizeUnit={'px'} size={15} color="#ffffff" loading={loading} />
    ) : (
      <p>{value}</p>
    )}
  </div>
);

export const CardReals = ({ loading, value }) => (
  <div className="card-saldo">
    {loading ? (
      <ClipLoader sizeUnit={'px'} size={15} color="#ffffff" loading={loading} />
    ) : (
      <>
        <p />
        <p>{`R$${formatNumberToCurrency(value)}`}</p>
      </>
    )}
  </div>
);

export const CardRealsSorocaba = ({ value }) => (
  <div>
    <p>{`R$${formatNumberToCurrency(value)}`}</p>
  </div>
);
