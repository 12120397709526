import {
  getActivations,
  getActivation,
  getLicenscePlate,
  park,
} from '../repositories';
import { toast } from 'react-toastify';
const initialState = {
  list: [],
  activation: {},
  activationSelected: {},
  possibleActivations: {},
};
export const activations = {
  state: initialState,
  reducers: {
    saveActivationsHistory(state, payload) {
      return {
        ...state,
        list: [...payload],
      };
    },
    selectActivation(state, payload) {
      return {
        ...state,
        activationSelected: payload,
      };
    },
    saveLicensePlate(state, licensePlate) {
      return {
        ...state,
        activation: {
          ...state.activation,
          licensePlate,
        },
      };
    },
    saveTickedId(state, ticketId) {
      return {
        ...state,
        activation: {
          ...state.activation,
          ticketId,
        },
      };
    },
    savePossibleActivation(state, payload) {
      return {
        ...state,
        possibleActivations: payload,
      };
    },
    clearStore() {
      return initialState;
    },
  },
  effects: dispatch => ({
    async getActivationsAsync(payload, rootState) {
      if (rootState.activations.list.length > 0) {
        return Promise.resolve();
      }
      try {
        const response = await getActivations(0, 20);
        dispatch.activations.saveActivationsHistory(response.data);
      } catch (err) {}
    },
    async refreshActivations(payload, rootState) {
      try {
        const response = await getActivations(0, 20, payload);
        dispatch.activations.saveActivationsHistory(response.data);
      } catch (err) {}
    },
    async getPossibleActivationsAsync({ licensePlate, quantity }, rootState) {
      try {
        const response = await getLicenscePlate(licensePlate, quantity);
        dispatch.activations.savePossibleActivation(response.data);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
    async getActivationAcync(payload, rootState) {
      try {
        const response = await getActivation(payload);
        dispatch.activations.selectActivation(response.data);
      } catch (err) {}
    },
    async parkAsync(payload, rootState) {
      const licensePlate = rootState.activations.activation.licensePlate;
      const ticketId = rootState.activations.activation.ticketId;
      try {
        const response = await park(licensePlate, ticketId, payload);
        toast.success('Estacionamento realizado com sucesso.');
        dispatch.balance.getBalanceAsync();
        dispatch.vehicles.refreshVehiclesAsync();
        dispatch.activations.refreshActivations();
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  }),
};
