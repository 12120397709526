import React, { PureComponent } from 'react';
import { Formik, Form, Field } from 'formik';
import { connect } from 'react-redux';
import { MoipValidator } from 'moip-sdk-js';
import moment from 'moment';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';

import { ModalConfirm } from './Confirm';
import ButtonLoading from '../../components/ButtonLoading';
import ErrorsMessage from '../../components/ErrorsMessage';
import { removeCharsDoc } from '../../utils/formatters';
import {
  getCardHash,
  getCardHashSorocaba,
  getCardHashItaperuna,
} from '../../utils/encryption';

const SignupSchema = Yup.object().shape({
  name: Yup.string().required('Favor informar o NOME do titular do cartão'),
  number: Yup.string()
    .required('Favor informar o NÚMERO do cartão')
    .test('numberCard', 'O NÚMERO do cartão informado é invalido', value =>
      value && MoipValidator.isValidNumber(value) ? true : false,
    ),
  expirationDate: Yup.string()
    .required('Favor informar a VALIDADE do cartão')
    .test('expirationDate', 'Data de VALIDADE informada é invalida', value => {
      if (!value) {
        return false;
      } else {
        const date = value.split('/');
        const month = date[0];
        const year = date[1];
        return MoipValidator.isExpiryDateValid(month, year);
      }
    }),
  cvc: Yup.string()
    .required('Favor informar o CCV do cartão')
    .test('minimum', 'O CCV deve conter pelo menos 3 caracteres', value =>
      !value || value.length < 3 ? false : true,
    ),
  cpf: Yup.string().required('Favor informar o CPF do titular do cartão'),
  birthDate: Yup.string()
    .required('Favor informar a data de nascimento do titular do cartão')
    .test(
      'date',
      'A data de nascimento informada é inválida DD/MM/AAAA',
      value =>
        value && value.length >= 10 && moment(value, 'DD/MM/YYYY').isValid()
          ? true
          : false,
    ),
});

class PaymentNewCard extends PureComponent {
  state = {
    creditos: 0,
    valor: 'RS0,00',
    modalVisible: false,
  };

  componentDidMount() {
    const { products, city, creditType } = this.props;
    var credito = null;
    if (creditType === 2) {
      credito = city.credits2.find(
        item => item.creditos === products[0].quantity,
      );
    } else {
      credito = city.credits.find(
        item => item.creditos === products[0].quantity,
      );
    }
    this.setState({
      ...credito,
    });
  }

  componentDidUpdate(prevProps) {
    const { products, city, creditType } = this.props;
    if (prevProps.products !== products && products.length > 0) {
      var credito = null;
      if (creditType === 2) {
        credito = city.credits2.find(
          item => item.creditos === products[0].quantity,
        );
      } else {
        credito = city.credits.find(
          item => item.creditos === products[0].quantity,
        );
      }
      this.setState({
        ...credito,
      });
    }
  }

  _order = ({ number, expirationDate, cvc, cpf, name, birthDate }) => {
    this.handleAlert();
    const {
      saveCard,
      order,
      loading,
      changeModalState,
      getUserAsyncInstance,
      city,
    } = this.props;
    const expirationMonth = expirationDate.split('/')[0];
    const expirationYear = '20' + expirationDate.split('/')[1];
    if (!loading) {
      const card = {
        number: number.replace(/\s+/g, ''),
        expirationMonth,
        expirationYear,
        cvc,
        store: true,
        holder: {
          name,
          birthDate: moment(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          cpf: removeCharsDoc(cpf),
        },
      };

      saveCard(card);
      order()
        .then(() => {
          getUserAsyncInstance()
            .then(response => {
              changeModalState();
            })
            .catch(err => err);
        })
        .catch(err => err);
    }
  };

  handleAlert = () => {
    this.setState(prevState => ({
      modalVisible: !prevState.modalVisible,
    }));
  };

  render() {
    const { valor, modalVisible } = this.state;
    const { changeModalState, loading, city } = this.props;
    return (
      <div className="modal">
        <div className="modal-content">
          <i onClick={changeModalState} className="fas fa-window-close" />
          <Formik
            initialValues={{
              name: '',
              number: '',
              expirationDate: '',
              cpf: '',
              birthDate: '',
              cvc: '',
            }}
            validationSchema={SignupSchema}
            onSubmit={(values, utils) => {
              const creditCardNumber = values.number;
              const cvc = values.cvc;
              if (MoipValidator.isSecurityCodeValid(creditCardNumber, cvc)) {
                this.handleAlert();
              } else {
                utils.setFieldError({
                  field: 'cvc',
                  errorMsg: 'O CCV informado é invalido',
                });
              }
            }}
          >
            {({ errors, touched, values }) => (
              <>
                <div className="box-payment-new-card">
                  <div className="scroll-value">
                    <Form className="form-credit-card">
                      <h3>Dados cartão de crédito</h3>
                      <div className="form-group">
                        <label htmlFor="name">Titular do cartão</label>
                        <Field id="name" type="text" name="name" />
                        {errors.name && touched.name ? (
                          <ErrorsMessage message={errors.name} />
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor="number">Número do cartão</label>
                        <Field
                          type="text"
                          name="number"
                          render={({ field }) => (
                            <InputMask
                              id="number"
                              maskChar={false}
                              mask="9999 9999 9999 9999"
                              {...field}
                            />
                          )}
                        />
                        {errors.number && touched.number ? (
                          <ErrorsMessage message={errors.number} />
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor="cvc">CCV do cartão</label>
                        <Field
                          type="text"
                          name="cvc"
                          render={({ field }) => (
                            <InputMask
                              maskChar={false}
                              id="cvc"
                              mask="9999"
                              {...field}
                            />
                          )}
                        />
                        {errors.cvc && touched.cvc ? (
                          <ErrorsMessage message={errors.cvc} />
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor="expirationDate">Validade</label>
                        <Field
                          type="text"
                          name="expirationDate"
                          render={({ field }) => (
                            <InputMask
                              maskChar={false}
                              id="expirationDate"
                              mask="99/99"
                              {...field}
                            />
                          )}
                        />
                        {errors.expirationDate && touched.expirationDate ? (
                          <ErrorsMessage message={errors.expirationDate} />
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor="cpf">CPF do titular</label>
                        <Field
                          type="text"
                          name="cpf"
                          render={({ field }) => (
                            <InputMask
                              maskChar={false}
                              id="cpf"
                              mask="999.999.999-99"
                              {...field}
                            />
                          )}
                        />
                        {errors.cpf && touched.cpf ? (
                          <ErrorsMessage message={errors.cpf} />
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label htmlFor="birthDate">
                          Data de nascimento titular
                        </label>
                        <Field
                          type="text"
                          name="birthDate"
                          render={({ field }) => (
                            <InputMask
                              maskChar={false}
                              id="birthDate"
                              mask="99/99/9999"
                              {...field}
                            />
                          )}
                        />
                        {errors.birthDate && touched.birthDate ? (
                          <ErrorsMessage message={errors.birthDate} />
                        ) : null}
                      </div>
                      <ButtonLoading
                        type="submit"
                        color="#67983d"
                        text="Comprar"
                        loading={loading}
                      />
                    </Form>
                    {modalVisible &&
                      (city.name !== 'Salvador' ? (
                        <ModalConfirm
                          cancel={this.handleAlert}
                          confirm={() => this._order(values)}
                          text={`Tem certeza que deseja comprar ${valor} em créditos ?`}
                        />
                      ) : (
                        <ModalConfirm
                          cancel={this.handleAlert}
                          confirm={() => this._order(values)}
                          text={`Tem certeza que deseja comprar ${valor} ?`}
                        />
                      ))}
                  </div>
                </div>
              </>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  products: state.order.products,
  loading: state.loading.effects.order.postOrderAsync,
  city: state.city,
  creditType: state.order.vehicleType.vehicleType,
});

const mapDispatch = dispatch => ({
  saveCard: payload => dispatch.order.saveCreditCard(payload),
  order: () => dispatch.order.postOrderAsync(),
  getUserAsyncInstance: payload => dispatch.user.getUserAsyncInstance(payload),
});

export default connect(
  mapState,
  mapDispatch,
)(PaymentNewCard);
