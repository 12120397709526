import { postOrder } from '../repositories';
import { toast } from 'react-toastify';

const initialState = {
  products: [],
  quantity: {},
  vehicleType: {},
  payment: {
    gateway: '',
    data: {
      method: '',
    },
  },
};

export const order = {
  state: initialState,
  reducers: {
    saveCreditType(state, payload) {
      return {
        ...state,
        vehicleType: { vehicleType: payload },
      };
    },
    saveCredits(state, payload) {
      return {
        ...state,
        products: [{ quantity: payload }],
      };
    },
    selectPayment(state, payload) {
      return {
        ...state,
        payment: {
          gateway: 'pagSeguro',
          data: {
            method: payload,
            ...state.payment.data.creditCard,
          },
        },
      };
    },
    saveCreditCard(state, payload) {
      return {
        ...state,
        payment: {
          gateway: 'pagSeguro',
          data: {
            ...state.payment.data,
            creditCard: payload,
          },
        },
      };
    },
    clearStore() {
      return initialState;
    },
  },
  effects: dispatch => ({
    async postOrderAsync(payload, rootState) {
      rootState.order.products = [
        {
          quantity: rootState.order.products[0].quantity,
          vehicleType: rootState.order.vehicleType.vehicleType,
        },
      ];
      try {
        let stateOrder = rootState;
        Object.assign(rootState.order, { origin: 'WEB' });
        if (rootState.city.name === 'Salvador') {
          const order = rootState.order;
          const newProducts = order.products.map(i => ({
            value: i.quantity,
          }));
          stateOrder = {
            order: {
              payment: order.payment,
              products: newProducts,
              origin: 'WEB',
            },
          };
        }

        const response = await postOrder(stateOrder.order);
        if (rootState.order.payment.data.method === 'BOLETO') {
          toast.success('Boleto gerado com sucesso.');
        } else {
          toast.success(
            'Pedido gerado com sucesso. Aguarde confirmação do pagamento.',
          );
          if (
            response.data &&
            Array.isArray(response.data.payments) &&
            response.data.payments.length > 0 &&
            !rootState.cards.list.find(
              item => response.data.payments[0].creditCard.id === item.id,
            )
          ) {
            dispatch.cards.saveCreditCard(response.data.payments[0].creditCard);
          }
        }
        dispatch.balance.getBalanceAsync();
        dispatch.orders.refreshOrders();
        return response.data;
      } catch (err) {
        throw err;
      }
    },
  }),
};
