import React from 'react';
import { toast } from 'react-toastify';

const CardsCard = props => {
  const { cards, selectCard } = props;

  const handleCard = card => {
    selectCard(card);
    toast.info('Cartão selecionado como principal', {
      position: 'bottom-right',
    });
  };

  return (
    <div className="box-sorocaba height-box-card">
      <h3>Meus cartões</h3>
      <div className="cards top-margin">
        {cards.list.map(i => (
          <button
            key={`card-list-${i.id}`}
            onClick={() => handleCard(i)}
            className={`cartao ${
              cards.selectedCard.id === i.id ? 'active' : ''
            }`}
          >
            <span>**** **** **** {getNumberCard(i.number)}</span>
            <span className={`bandeira ${i.brand}`} />
          </button>
        ))}
      </div>
    </div>
  );
};

const getNumberCard = number => {
  const end = number.split(' ');
  return end[end.length - 1];
};

export default CardsCard;
