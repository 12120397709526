import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import PaymentCard from './PaymentCard';
import PaymentNewCard from './PaymentNewCard';

class DadosCartaoCredito extends PureComponent {
  constructor(props) {
    super(props);
    const { cards, selectedCard } = props;
    const isNotSelectedCard = cards.length && !Object.keys(selectedCard).length;
    if (isNotSelectedCard) {
      toast.info(
        'Você possui cartões, mas nenhum deles está selecionado para compras, selecione-o na área de Cartões de Crédito para seu conforto.',
      );
    }
    this.state = {
      isNotSelectedCard: isNotSelectedCard,
      modalVisible: false,
    };
  }

  handleRemoveSelectedCard = () => {
    this.setState({
      isNotSelectedCard: true,
    });
  };

  render() {
    const { isNotSelectedCard } = this.state;
    const { changeModalState, selectedCard } = this.props;

    return <PaymentNewCard changeModalState={changeModalState} />;
    // return isNotSelectedCard || Object.keys(selectedCard).length === 0 ? (
    //   <PaymentNewCard changeModalState={changeModalState} />
    // ) : (
    //   <PaymentCard
    //     changeModalState={changeModalState}
    //     unselectCard={this.handleRemoveSelectedCard}
    //   />
    // );
  }
}

const mapState = state => ({
  cards: state.cards.list,
  selectedCard: state.cards.selectedCard,
});

const mapDispatch = dispatch => ({
  selectPayment: payment => dispatch.order.selectPayment(payment),
});

export default connect(
  mapState,
  mapDispatch,
)(DadosCartaoCredito);
