import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

class FormaPagamentoReais extends PureComponent {
  state = {
    creditos: 0,
    valor: 'RS0,00',
  };

  componentDidMount() {
    const { products, city } = this.props;
    const credito = city.credits.find(
      item => item.creditos === products[0].quantity,
    );
    this.setState({
      ...credito,
    });
  }

  componentDidUpdate(prevProps) {
    const { products, city } = this.props;
    if (prevProps.products !== products && products.length > 0) {
      const credito = city.credits.find(
        item => item.id === products[0].quantity,
      );
      this.setState({
        ...credito,
      });
    }
  }

  selectPaymentType = type => {
    const {
      changeModalState,
      changePaymentModalState,
      selectPayment,
    } = this.props;
    const { creditos } = this.state;
    if (creditos >= 20 || type !== 'BOLETO') {
      selectPayment(type);
      changeModalState();
      changePaymentModalState();
    }
  };

  render() {
    const { changeModalState } = this.props;
    const { creditos, valor } = this.state;

    return (
      <div className="modal">
        <div className="modal-content">
          <i className="fas fa-window-close" onClick={changeModalState} />
          <h3>Forma de Pagamento</h3>
          <form>
            <p className="upper">{valor}</p>
            <p className="small">Escolha a forma de pagamento</p>
            <div className="radios">
              <div className="radio">
                <input
                  type="radio"
                  onChange={() => this.selectPaymentType('CREDIT_CARD')}
                  id="pagamento"
                  name="pagamento"
                />
                <label className="payment-type" htmlFor="pagamento">
                  <i className="fas fa-credit-card" />
                  Cartão de crédito
                </label>
              </div>
              {creditos > 19 && (
                <div className="radio">
                  <input
                    onChange={() => this.selectPaymentType('BOLETO')}
                    type="radio"
                    id="boleto"
                    name="pagamento"
                    disabled={creditos < 20}
                  />
                  <label className="payment-type" htmlFor="boleto">
                    <i className="fas fa-barcode" />
                    Boleto Bancário
                  </label>
                </div>
              )}
            </div>
            {creditos < 20 && (
              <p className="small">
                Pagamento com boleto bancário somente acima de R$20,00
              </p>
            )}
          </form>
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  products: state.order.products,
  cards: state.cards.list,
  selectedCard: state.cards.selectedCard,
  city: state.city,
});

const mapDispatch = dispatch => ({
  selectPayment: payment => dispatch.order.selectPayment(payment),
});

export default connect(
  mapState,
  mapDispatch,
)(FormaPagamentoReais);
