export const BASE_PATH = '';
export const LOGIN = 'https://autentica.timob.com.br';
export const REGISTER = 'https://cadastra.timob.com.br';
export const TRANSACIONA = 'https://transaciona.timob.com.br';
export const VOUCHER = 'https://voucher.timob.com.br';
export const MAPS_KEY = 'AIzaSyCAog6e97iRtgeJevBVWIsrSE2vcHz58iI';

export const MINUTE = 60 * 1000;
export const LATITUDE_DELTA = 0.0022;
export const LATITUDE_DELTA_BONUS = 0.0122;

export const Produtos = {
  ROTATIVO: '1',
  BONUS: '2',
};

export const sleep = time =>
  new Promise(resolve => setTimeout(() => resolve(), time));

export const statusOrder = [
  {
    label: 'Aguardando Pagamento',
    value: 'WAITING',
  },
  {
    label: 'Pago',
    value: 'PAID',
  },
  {
    label: 'Recusado',
    value: 'REFUSED',
  },
  {
    label: 'Cancelado',
    value: 'CANCELED',
  },
];

export const CREDITS_BH = [
  {
    creditos: 1,
    valor: 'R$4,40',
  },
  {
    creditos: 2,
    valor: 'R$8,80',
  },
  {
    creditos: 5,
    valor: 'R$22,00',
  },
  {
    creditos: 10,
    valor: 'R$44,00',
  },
];

export const CREDITS_SOROCABA = [
  {
    creditos: 10,
    valor: 'R$15,00',
  },
  {
    creditos: 15,
    valor: 'R$22,50',
  },
  {
    creditos: 20,
    valor: 'R$30,00',
  },
  {
    creditos: 30,
    valor: 'R$45,00',
  },
];

export const CREDITS_SALVADOR = [
  {
    creditos: 3,
    valor: 'R$3,00',
  },
  {
    creditos: 6,
    valor: 'R$6,00',
  },
  {
    creditos: 9,
    valor: 'R$9,00',
  },
  {
    creditos: 18,
    valor: 'R$18,00',
  },
];

export const CREDITS_IGARAPE = [
  {
    creditos: 10,
    valor: 'R$5,00',
  },
  {
    creditos: 20,
    valor: 'R$10,00',
  },
  {
    creditos: 30,
    valor: 'R$15,00',
  },
  {
    creditos: 50,
    valor: 'R$25,00',
  },
];

export const CREDITS_JANAUBA = [
  {
    creditos: 5,
    valor: 'R$10,00',
  },
  {
    creditos: 10,
    valor: 'R$20,00',
  },
  {
    creditos: 20,
    valor: 'R$40,00',
  },
  {
    creditos: 30,
    valor: 'R$60,00',
  },
];

export const CREDITS_ITAPERUNA_CARRO = [
  {
    creditos: 20,
    valor: 'R$10,00',
  },
  {
    creditos: 40,
    valor: 'R$20,00',
  },
  {
    creditos: 60,
    valor: 'R$30,00',
  },
  {
    creditos: 100,
    valor: 'R$50,00',
  },
];

export const CREDITS_ITAPERUNA_MOTO = [
  {
    creditos: 10,
    valor: 'R$5,00',
  },
  {
    creditos: 20,
    valor: 'R$10,00',
  },
  {
    creditos: 30,
    valor: 'R$15,00',
  },
  {
    creditos: 50,
    valor: 'R$25,00',
  },
];

export const CREDITS_PATOS_CARRO = [
  {
    creditos: 5,
    valor: 'R$10,00',
  },
  {
    creditos: 10,
    valor: 'R$20,00',
  },
  {
    creditos: 15,
    valor: 'R$30,00',
  },
  {
    creditos: 20,
    valor: 'R$50,00',
  },
];

export const CREDITS_PATOS_MOTO = [
  {
    creditos: 10,
    valor: 'R$10,00',
  },
  {
    creditos: 20,
    valor: 'R$20,00',
  },
  {
    creditos: 30,
    valor: 'R$30,00',
  },
  {
    creditos: 50,
    valor: 'R$50,00',
  },
];

export const CREDITS_LEGADO_CARRO = [
  {
    creditos: 10,
    valor: 'R$20,00',
  },
  {
    creditos: 20,
    valor: 'R$40,00',
  },
  {
    creditos: 30,
    valor: 'R$50,00',
  },
  {
    creditos: 40,
    valor: 'R$80,00',
  },
];

export const CREDITS_ITARARE = [
  {
    creditos: 10,
    valor: 'R$15,00',
  },
  {
    creditos: 20,
    valor: 'R$30,00',
  },
  {
    creditos: 30,
    valor: 'R$45,00',
  },
  {
    creditos: 40,
    valor: 'R$60,00',
  },
];

export const CREDITS_DIVINOPOLIS = [
  {
    creditos: 50,
    valor: 'R$25,00',
  },
  {
    creditos: 60,
    valor: 'R$30,00',
  },
  {
    creditos: 80,
    valor: 'R$40,00',
  },
  {
    creditos: 100,
    valor: 'R$50,00',
  },
];

export const CREDITS_OURO_PRETO = [
  {
    creditos: 40,
    valor: 'R$20,00',
  },
  {
    creditos: 30,
    valor: 'R$30,00',
  },
  {
    creditos: 80,
    valor: 'R$40,00',
  },
  {
    creditos: 100,
    valor: 'R$50,00',
  },
];

export const CREDITS_LEGADO_MOTO = [
  {
    creditos: 10,
    valor: 'R$10,00',
  },
  {
    creditos: 20,
    valor: 'R$20,00',
  },
  {
    creditos: 30,
    valor: 'R$30,00',
  },
  {
    creditos: 40,
    valor: 'R$40,00',
  },
];

export const CREDITS_NEVES = [
  {
    creditos: 10,
    valor: 'R$20,00',
  },
  {
    creditos: 20,
    valor: 'R$40,00',
  },
  {
    creditos: 30,
    valor: 'R$60,00',
  },
  {
    creditos: 40,
    valor: 'R$80,00',
  },
];

export const DOMAINS = [
  {
    key: 'belohorizonte',
    name: 'Belo Horizonte',
    credits: CREDITS_BH,
    parkingTime: {
      carro: [60, 120, 300, 720],
    },
    products: {
      carro: '1',
    },
  },
  {
    key: 'divinopolis',
    name: 'Divinópolis',
    credits: CREDITS_DIVINOPOLIS,
    parkingTime: {
      carro: [60, 120, 240],
    },
    products: {
      carro: '7',
    },
  },
  {
    key: 'salvador',
    name: 'Salvador',
    credits: CREDITS_SALVADOR,
  },
  {
    key: 'sorocaba',
    name: 'Sorocaba',
    credits: CREDITS_SOROCABA,
    parkingTime: {
      carro: [60, 120],
    },
    products: {
      carro: '5',
    },
  },
  {
    key: 'patosdeminas',
    name: 'Patos de Minas',
    credits: CREDITS_PATOS_CARRO,
    credits2: CREDITS_PATOS_MOTO,
    parkingTime: {
      carro: [60, 120],
      moto: [60, 120],
    },
    products: {
      carro: '7',
      moto: '8',
    },
  },
  {
    key: 'igarape',
    name: 'Igarapé',
    credits: CREDITS_IGARAPE,
    parkingTime: {
      carro: [60, 120],
    },
    products: {
      carro: '12',
      moto: '13',
    },
  },
  {
    key: 'itaperuna',
    name: 'Itaperuna',
    credits: CREDITS_ITAPERUNA_CARRO,
    credits2: CREDITS_ITAPERUNA_MOTO,
    parkingTime: {
      carro: [60],
      moto: [60],
    },
    products: {
      carro: '13',
      moto: '13',
    },
  },
  {
    key: 'lafaiete',
    name: 'Conselheiro Lafaiete',
    credits: CREDITS_LEGADO_CARRO,
    credits2: CREDITS_LEGADO_MOTO,
    parkingTime: {
      carro: [120, 240],
      moto: [120, 240],
    },
    products: {
      carro: '7',
      moto: '8',
    },
  },
  {
    key: 'monlevade',
    name: 'João Monlevade',
    credits: CREDITS_LEGADO_CARRO,
    credits2: CREDITS_LEGADO_MOTO,
    parkingTime: {
      carro: [60, 120],
      moto: [60, 120],
    },
    products: {
      carro: '7',
      moto: '8',
    },
  },
  {
    key: 'passos',
    name: 'Passos',
    credits: CREDITS_LEGADO_CARRO,
    parkingTime: {
      carro: [60, 120],
    },
    products: {
      carro: '7',
    },
  },
  {
    key: 'neves',
    name: 'Ribeirão das Neves',
    credits: CREDITS_NEVES,
    parkingTime: {
      carro: [60, 120, 240],
    },
    products: {
      carro: '7',
    },
  },
  {
    key: 'vespasiano',
    name: 'Vespasiano',
    credits: CREDITS_LEGADO_CARRO,
    parkingTime: {
      carro: [60, 120],
    },
    products: {
      carro: '7',
    },
  },
  {
    key: 'itarare',
    name: 'Itararé',
    credits: CREDITS_ITARARE,
    parkingTime: {
      carro: [60, 120],
    },
    products: {
      carro: '12',
    },
  },
  {
    key: 'janauba',
    name: 'Janaúba',
    credits: CREDITS_JANAUBA,
    parkingTime: {
      carro: [120, 240],
    },
    products: {
      carro: '7',
    },
  },
];

export const customMapStyle = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#1d2c4d',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#8ec3b9',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#1a3646',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#4b6878',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#64779e',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#d59563',
      },
    ],
  },
  {
    featureType: 'administrative.province',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#4b6878',
      },
    ],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#334e87',
      },
    ],
  },
  {
    featureType: 'landscape.natural',
    elementType: 'geometry',
    stylers: [
      {
        color: '#023e58',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#283d6a',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#6f9ba5',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#1d2c4d',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#263c3f',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#023e58',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#3C7680',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#304a7d',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#212a37',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#98a5be',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#1d2c4d',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#2c6675',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#255763',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#b0d5ce',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#023e58',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      {
        color: '#2f3948',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#98a5be',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#1d2c4d',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#283d6a',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#3a4762',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#d59563',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#0e1626',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#4e6d70',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#17263c',
      },
    ],
  },
];
