import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
//import moment from 'moment';
import moment from 'moment-timezone';
import { formatLicensePlate } from '../../utils/formatters';
import RemoverVeiculo from '../../pages/modal/RemoverVeiculo';
import EstacioanrVeiculo from '../../pages/modal/EstacionarVeiculo';
import { MINUTE } from '../../utils/constants';
import Countdown from 'react-countdown-now';

class VehicleCard extends PureComponent {
  state = {
    modalVisible: false,
    modalPark: false,
    isLicensePlateActive: false,
  };

  componentDidMount = () => {
    const [, isLicensePlateActive] = this._getTimeDiff();
    if (isLicensePlateActive && !this.state.isLicensePlateActive) {
      this.setState({ isLicensePlateActive: true });
    }
  };

  handleModalDelete = () => {
    this.setState(prevState => ({ modalVisible: !prevState.modalVisible }));
  };

  handleModalPark = () => {
    this.setState(prevState => ({ modalPark: !prevState.modalPark }));
  };

  handleDelete = () => {
    this.props
      .deleteVehicles(this.props.item.licensePlate)
      .then(() => this.setState({ modalVisible: false }))
      .catch(err => err);
  };

  _getTimeDiff = () => {
    const { item } = this.props;
    const now = moment().tz('America/Sao_Paulo');
    const lastActivation = item.ticketLastActivation
      ? moment(item.ticketLastActivation.createdAt).tz('America/Sao_Paulo')
      : 0;
    const timeElapsed = item.ticketLastActivation
      ? now.diff(lastActivation)
      : 0;
    const isLicensePlateActive =
      item.ticketLastActivation &&
      timeElapsed < item.ticketLastActivation.parkingTime * MINUTE;
    const timeDiff = item.ticketLastActivation
      ? item.ticketLastActivation.parkingTime * MINUTE - timeElapsed
      : 0;
    return [timeDiff, timeElapsed, isLicensePlateActive];
  };

  _timeNotInformedButActive = () => {
    const { item } = this.props;
    const timeNotInformed =
      item.ticketLastActivation &&
      moment().isSame(moment(item.ticketLastActivation.createdAt), 'day') &&
      item.ticketLastActivation.parkingTime === null;
    return timeNotInformed;
  };

  timeElapsed = () => {
    if (!this.state.timeExceeded) {
      this.setState({ timeExceeded: true, isLicensePlateActive: false });
    }
  };

  render() {
    const { modalVisible, modalPark, isLicensePlateActive } = this.state;
    const {
      loading,
      item: { licensePlate, model, type, ticketLastActivation },
      user,
    } = this.props;

    const timeNotInformed = this._timeNotInformedButActive();
    const [timeDiff] = this._getTimeDiff();
    return (
      <div className="carro">
        <div className="modelo">{model}</div>
        <div className="placa">{formatLicensePlate(licensePlate)}</div>
        {user && user.type === 'J' ? (
          (timeDiff > 0 && isLicensePlateActive) || timeNotInformed ? (
            <div className="ativacao">
              {timeNotInformed ? (
                <p>Tempo não informado</p>
              ) : (
                <>
                  <div className="contador">
                    <i className="fas fa-clock" />
                    <Countdown
                      date={Date.now() + timeDiff}
                      daysInHours={true}
                      onComplete={this.timeElapsed}
                    />
                  </div>
                  <button
                    className="btn-renovar"
                    onClick={this.handleModalPark}
                  >
                    <i className="fas fa-sync refresh-icon" />
                  </button>
                </>
              )}
            </div>
          ) : (
            <div className="ativacao">
              <button
                onClick={this.handleModalPark}
                type="button"
                className="estacionar"
              >
                <p>ESTACIONAR</p>
              </button>
            </div>
          )
        ) : (
          <div className="ativacao">
            Última ativação
            <br />
            {ticketLastActivation
              ? moment(ticketLastActivation.createdAt).format(
                  'DD/MM/YYYY [às] HH:mm',
                )
              : 'Sem Registro'}
          </div>
        )}

        {type === 1 ? (
          <div className="img-carro" />
        ) : type === 4 ? (
          <div className="img-moto" />
        ) : type === 5 ? (
          <div className="img-carga" />
        ) : (
          <div className="img-moto" />
        )}
        <button
          onClick={this.handleModalDelete}
          type="button"
          className="excluir"
        >
          <i className="fas fa-trash" />
        </button>
        {modalVisible && (
          <RemoverVeiculo
            loading={loading}
            licensePlate={formatLicensePlate(licensePlate)}
            cancel={this.handleModalDelete}
            confirm={this.handleDelete}
          />
        )}
        {modalPark && (
          <EstacioanrVeiculo
            loading={loading}
            licensePlate={licensePlate}
            vehicleType={type}
            cancel={this.handleModalPark}
            confirm={this.handlePark}
          />
        )}
      </div>
    );
  }
}

const mapState = state => ({
  loading: state.loading.effects.vehicles.deleteVehiclesAsync,
  loadingPossibleActivations:
    state.loading.effects.activations.getPossibleActivationsAsync,
  user: state.user,
  city: state.city,
});

const mapDispatch = dispatch => ({
  getPossibleActivations: (licensePlate, quantity) =>
    dispatch.activations.getPossibleActivationsAsync({
      licensePlate,
      quantity,
    }),
  saveTickedId: ticketId => dispatch.activations.saveTickedId(ticketId),
  saveLicensePlate: licensePlate =>
    dispatch.activations.saveLicensePlate(licensePlate),
  deleteVehicles: licensePlate =>
    dispatch.vehicles.deleteVehiclesAsync(licensePlate),
});

export default connect(
  mapState,
  mapDispatch,
)(VehicleCard);
