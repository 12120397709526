import React, { PureComponent } from 'react';
import { Link, navigate } from '@reach/router';
import { Formik, Form, Field } from 'formik';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { removeCharsDoc } from '../../utils/formatters';

import ErrorsMessage from '../../components/ErrorsMessage';
import Splash from '../../components/Splash';
import bg_urbes from './../../assets/images/bg-urbes.jpg';

const propTypes = {
  loading: PropTypes.bool.isRequired,
  forgotPassword: PropTypes.func.isRequired,
};

const SignupSchema = Yup.object().shape({
  doc: Yup.string().required('Favor informar o CPF/CNPJ'),
});
class ForgotPassword extends PureComponent {
  constructor(props) {
    super(props);
    const {
      location: { state },
    } = props;

    this.state = {
      doc: state && state.doc ? state.doc : '',
      maskDoc: '999.999.999-999',
    };
  }

  handleDoc = event => {
    if (removeCharsDoc(event.value).length >= 12)
      this.setState({ maskDoc: '99.999.999/9999-99' });
    else this.setState({ maskDoc: '999.999.999-999' });
  };

  render() {
    const { loading } = this.props;
    return (
      <div className="content-login-urbes">
        <div className="div-urbes">
          <img src={bg_urbes} alt="bg-urbes-img" />
        </div>
        {loading && <Splash />}
        <div className="form-login-urbes">
          <Formik
            initialValues={{
              doc: '',
            }}
            validationSchema={SignupSchema}
            onSubmit={values => {
              const { forgotPassword } = this.props;
              forgotPassword(removeCharsDoc(values.doc))
                .then(() => navigate('/login', { state: { doc: values.doc } }))
                .catch(err => err);
            }}
          >
            {({ errors, touched, values }) => (
              <Form>
                <h1>Esqueceu sua senha</h1>
                <h5>
                  ou
                  <Link to="/login">faça seu login.</Link>
                </h5>
                <div className="form-group">
                  <Field
                    type="number"
                    name="doc"
                    render={({ field }) => (
                      <InputMask
                        maskChar={false}
                        mask={this.state.maskDoc}
                        onChange={this.handleDoc(field)}
                        placeholder="CPF/CNPJ"
                        {...field}
                      />
                    )}
                  />
                  {errors.doc && touched.doc ? (
                    <ErrorsMessage message={errors.doc} />
                  ) : null}
                </div>
                <button type="submit" className="btn btn-green">
                  Recuperar senha
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

ForgotPassword.propTypes = propTypes;

const mapState = state => ({
  loading: state.loading.effects.user.forgotPasswordAsync,
});

const mapDispatch = dispatch => ({
  forgotPassword: cpf => dispatch.user.forgotPasswordAsync(cpf),
});

export default connect(
  mapState,
  mapDispatch,
)(ForgotPassword);
