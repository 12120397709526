import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from '@reach/router';

class PrivateRouter extends PureComponent {
  render() {
    const {
      Component,
      location: { state },
      ...rest
    } = this.props;
    const { user, city } = rest;
    const withPromo = state && state.promoCode ? state.promoCode : null;
    return user && Object.keys(user).length > 0 && user.token ? (
      <Component withPromo={withPromo} />
    ) : Object.keys(city).length > 0 && city.key !== '' ? (
      <Redirect to={`/${city.key}`} />
    ) : (
      <Redirect to="/login" />
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  city: state.city,
});

export default connect(mapStateToProps)(PrivateRouter);
