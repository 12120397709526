import React from 'react';

export const ModalConfirm = ({ confirm, cancel, text }) => {
  return (
    <div className="modal-confirm">
      <div className="modal-content">
        <h3>Atenção</h3>
        <i className="fas fa-window-close" onClick={cancel} />
        <p>{text}</p>
        <div className="modal-footer">
          <button className="btn-ok" onClick={confirm} type="button">
            OK
          </button>
          <button className="btn-cancel" onClick={cancel} type="button">
            CANCELAR
          </button>
        </div>
      </div>
    </div>
  );
};
