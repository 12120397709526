import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';

import { ModalConfirm } from './Confirm';
import ErrorsMessage from '../../components/ErrorsMessage';
import ButtonLoading from '../../components/ButtonLoading';

const SignupSchema = Yup.object().shape({
  cvc: Yup.string()
    .required('Favor informar o CCV do cartão')
    .test('Minimum', 'O CCV deve conter pelo menos 3 caracteres', value =>
      !value || value.length < 3 ? false : true,
    ),
});

class PaymentCard extends PureComponent {
  state = {
    creditos: 0,
    valor: 'RS0,00',
    modalVisible: false,
  };

  componentDidMount() {
    const { products, city, creditType } = this.props;
    var credito = null;
    if (creditType === 2) {
      credito = city.credits2.find(
        item => item.creditos === products[0].quantity,
      );
    } else {
      credito = city.credits.find(
        item => item.creditos === products[0].quantity,
      );
    }
    this.setState({
      ...credito,
    });
  }

  componentDidUpdate(prevProps) {
    const { products, city, creditType } = this.props;
    if (prevProps.products !== products && products.length > 0) {
      var credito = null;
      if (creditType === 2) {
        credito = city.credits2.find(
          item => item.creditos === products[0].quantity,
        );
      } else {
        credito = city.credits.find(
          item => item.creditos === products[0].quantity,
        );
      }
      this.setState({
        ...credito,
      });
    }
  }

  _order = values => {
    this.handleAlert();
    const { cvc } = this.state;
    const {
      saveCard,
      order,
      loading,
      selectedCard: { id },
      changeModalState,
    } = this.props;
    if (!loading) {
      saveCard({
        cvc,
        id,
      });
      order()
        .then(() => {
          changeModalState();
        })
        .catch(err => err);
    }
  };

  getCardIcon = brand => {
    if (brand.toLowerCase().includes('visa')) {
      return 'cc-visa';
    } else if (brand.toLowerCase().includes('master')) {
      return 'cc-mastercard';
    } else if (brand.toLowerCase().includes('mex')) {
      return 'cc-amex';
    }
    return false;
  };

  handleAlert = () => {
    this.setState(prevState => ({
      modalVisible: !prevState.modalVisible,
    }));
  };

  handleSubmit = values => {
    this.setState(prevState => ({
      modalVisible: !prevState.modalVisible,
      ...values,
    }));
  };

  render() {
    const { valor, modalVisible } = this.state;
    const { selectedCard, changeModalState, loading, city } = this.props;

    return (
      <div className="modal">
        <div className="modal-content">
          <i onClick={changeModalState} className="fas fa-window-close" />
          <Formik
            initialValues={{
              cvc: '',
            }}
            validationSchema={SignupSchema}
            onSubmit={values => {
              this.handleSubmit(values);
            }}
          >
            {({ errors, touched, values }) => (
              <>
                <Form className="form-credit-card">
                  <div className="credit-card">
                    <div className="flag">
                      <i
                        className={`fab fa-${this.getCardIcon(
                          selectedCard.brand || '',
                        )}`}
                      />
                    </div>
                    <div className="number">{selectedCard.number}</div>
                    <div className="info">
                      <div className="name">
                        <p>Titular do cartão</p>
                        <h4>
                          {selectedCard.holder && selectedCard.holder.name}
                        </h4>
                      </div>
                      <div className="date">
                        <p>Validade</p>
                        <h4>**/**</h4>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="cvc">CCV do cartão</label>
                    <Field
                      type="text"
                      name="cvc"
                      render={({ field }) => (
                        <InputMask
                          id="cvc"
                          maskChar={false}
                          mask="9999"
                          {...field}
                        />
                      )}
                    />
                    {errors.cvc && touched.cvc ? (
                      <ErrorsMessage message={errors.cvc} />
                    ) : null}
                  </div>
                  <ButtonLoading
                    type="submit"
                    color="#67983d"
                    text="Comprar"
                    loading={loading}
                  />
                  <ButtonLoading
                    callback={this.props.unselectCard}
                    color="rgba(28, 86,147, 0.8)"
                    text="Novo cartão"
                    loading={loading}
                  />
                </Form>
                {modalVisible &&
                  (city.name !== 'Salvador' ? (
                    <ModalConfirm
                      cancel={this.handleAlert}
                      confirm={this._order}
                      text={`Tem certeza que deseja comprar ${valor} em créditos ?`}
                    />
                  ) : (
                    <ModalConfirm
                      cancel={this.handleAlert}
                      confirm={this._order}
                      text={`Tem certeza que deseja comprar ${valor} ?`}
                    />
                  ))}
              </>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  products: state.order.products,
  selectedCard: state.cards.selectedCard,
  loading: state.loading.effects.order.postOrderAsync,
  city: state.city,
  creditType: state.order.vehicleType.vehicleType,
});

const mapDispatch = dispatch => ({
  saveCard: payload => dispatch.order.saveCreditCard(payload),
  order: () => dispatch.order.postOrderAsync(),
});

export default connect(
  mapState,
  mapDispatch,
)(PaymentCard);
