import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

class FormaPagamento extends PureComponent {
  state = {
    creditos: 0,
    valor: 'RS0,00',
  };

  componentDidMount() {
    const { products, city, creditType } = this.props;
    var credito = null;
    if (creditType === 2) {
      credito = city.credits2.find(
        item => item.creditos === products[0].quantity,
      );
    } else {
      credito = city.credits.find(
        item => item.creditos === products[0].quantity,
      );
    }
    this.setState({
      ...credito,
    });
  }

  componentDidUpdate(prevProps) {
    const { products, city, creditType } = this.props;

    if (prevProps.products !== products && products.length > 0) {
      var credito = null;
      if (creditType === 2) {
        credito = city.credits2.find(
          item => item.creditos === products[0].quantity,
        );
      } else {
        credito = city.credits.find(
          item => item.creditos === products[0].quantity,
        );
      }
      this.setState({
        ...credito,
      });
    }
  }

  selectPaymentType = type => {
    const {
      changeModalState,
      changePaymentModalState,
      selectPayment,
    } = this.props;

    const { creditos } = this.state;
    if (creditos >= 5 || type !== 'BOLETO') {
      selectPayment(type);
      changeModalState();
      changePaymentModalState();
    }
  };

  render() {
    const { changeModalState, city, creditType } = this.props;
    const { creditos, valor } = this.state;

    const boletoComp = (
      <div className="radio">
        <input
          onChange={() => this.selectPaymentType('BOLETO')}
          type="radio"
          id="boleto"
          name="pagamento"
          disabled={creditos < 5}
        />
        <label className="payment-type" htmlFor="boleto">
          <i className="fas fa-barcode" />
          Boleto Bancário
        </label>
      </div>
    );

    return (
      <div className="modal">
        <div className="modal-content">
          <i className="fas fa-window-close" onClick={changeModalState} />
          <h3>Forma de Pagamento</h3>
          <form>
            <p className="upper">
              {`${creditos} ${creditos > 1 ? 'créditos' : 'crédito'}`} rotativo
              {creditType === 2 ? ' Moto' : ' Carro'} = {valor}
            </p>

            <div className="radios">
              <div className="radio">
                <input
                  type="radio"
                  onChange={() => this.selectPaymentType('CREDIT_CARD')}
                  id="pagamento"
                  name="pagamento"
                />
                <label className="payment-type" htmlFor="pagamento">
                  <i className="fas fa-credit-card" />
                  Cartão de crédito
                </label>
              </div>
              {(city.name === 'Igarapé' && creditos > 30) ||
              (city.name === 'Itabira' && creditos >= 10) ||
              (city.name !== 'Sorocaba' &&
                city.name !== 'Itabira' &&
                creditos > 2)
                ? boletoComp
                : null}
            </div>
            {(city.name === 'Igarapé' && creditos <= 30) ||
            (city.name === 'Itabira' && creditos <= 9) ||
            creditos <= 2 ? (
              <p className="small">
                Pagamento com boleto bancário somente acima de R$20,00
              </p>
            ) : (
              <></>
            )}
          </form>
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  products: state.order.products,
  cards: state.cards.list,
  selectedCard: state.cards.selectedCard,
  city: state.city,
  creditType: state.order.vehicleType.vehicleType,
});

const mapDispatch = dispatch => ({
  selectPayment: payment => dispatch.order.selectPayment(payment),
});

export default connect(
  mapState,
  mapDispatch,
)(FormaPagamento);
