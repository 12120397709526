import { deleteCard } from '../repositories';

const initialState = {
  list: [],
  selectedCard: {},
};
export const cards = {
  state: initialState,
  reducers: {
    saveCreditCard(state, payload) {
      return {
        ...state,
        selectedCard: { ...payload },
      };
    },
    selectCard(state, payload) {
      return {
        ...state,
        selectedCard: { ...payload },
      };
    },
    deleteCard(state, payload) {
      const indexOf = state.list.indexOf(payload);
      return {
        ...state,
        list:
          indexOf !== undefined
            ? [
                ...state.list.slice(0, indexOf),
                ...state.list.slice(indexOf + 1),
              ]
            : [...state.list],
      };
    },
    unselectCard(state, payload) {
      return {
        ...state,
        selectedCard: { ...state.list[state.list.length - 1] },
      };
    },
    saveCards(state, payload) {
      return {
        ...state,
        list: [...payload],
      };
    },
    clearStore() {
      return initialState;
    },
  },
  effects: dispatch => ({
    async deleteCardAsync({ gateway, id }, rootState) {
      try {
        await deleteCard(gateway, id);
        await dispatch.user.getUserAsync();
        if (
          rootState.cards.selectedCard &&
          rootState.cards.selectedCard.id === id
        ) {
          dispatch.cards.unselectCard();
        }
      } catch (e) {
        throw e;
      }
    },
  }),
};
