import React, { PureComponent } from 'react';
import { Formik, Form, Field } from 'formik';
import { Link, navigate } from '@reach/router';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import { DOMAINS } from '../../utils/constants';
import { removeCharsDoc } from '../../utils/formatters';
import { formatLicensePlate } from '../../utils/formatters';

import ErrorsMessage from '../../components/ErrorsMessage';
import Splash from '../../components/Splash';

const SignupSchema = Yup.object().shape({
  doc: Yup.string().required('Favor informar o CPF/CNPJ'),
  name: Yup.string().required('Favor informar o NOME/RAZÃO SOCIAL'),
  mobile: Yup.string().required('Favor informar o CELULAR'),
  email: Yup.string()
    .email('E-MAIL inválido')
    .required('Favor informar o E-MAIL'),
  password: Yup.string()
    .required('Favor informar a SENHA')
    .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,15}$/, {
      message:
        'Sua SENHA deve ter entre 6 e 15 caracteres, com pelo menos uma letra e um número',
      excludeEmptyString: true,
    }),
  confirmationPass: Yup.string()
    .required('Favor informar a CONFIRMAÇÃO DE SENHA')
    .oneOf([Yup.ref('password'), null], 'As Senhas digitadas não conferem.')
    .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,15}$/, {
      message:
        'Sua SENHA deve ter entre 6 e 15 caracteres, com pelo menos uma letra e um número',
      excludeEmptyString: true,
    }),
});

const SignupSchemaWBoard = Yup.object().shape({
  doc: Yup.string().required('Favor informar o CPF/CNPJ'),
  name: Yup.string().required('Favor informar o NOME/RAZÃO SOCIAL'),
  mobile: Yup.string().required('Favor informar o CELULAR'),
  email: Yup.string()
    .email('E-MAIL inválido')
    .required('Favor informar o E-MAIL'),
  password: Yup.string()
    .required('Favor informar a SENHA')
    .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,15}$/, {
      message:
        'Sua SENHA deve ter entre 6 e 15 caracteres, com pelo menos uma letra e um número',
      excludeEmptyString: true,
    }),
  confirmationPass: Yup.string()
    .required('Favor informar a CONFIRMAÇÃO DE SENHA')
    .oneOf([Yup.ref('password'), null], 'As Senhas digitadas não conferem.')
    .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,15}$/, {
      message:
        'Sua SENHA deve ter entre 6 e 15 caracteres, com pelo menos uma letra e um número',
      excludeEmptyString: true,
    }),
  board: Yup.string().required('Favor informar o PLACA'),
});

class Cadastro extends PureComponent {
  state = {
    validateCpfCnpj: undefined,
    registerType: 'pf',
  };

  handleValidation = () => {
    const { city } = this.props;
    if (city.key === 'salvador') {
      return SignupSchemaWBoard;
    }
    return SignupSchema;
  };

  validateCpfCnpjExists = cpfCnpj => {
    const { checkCPFCNPJ } = this.props;
    const { validateCpfCnpj, registerType } = this.state;
    const newCpfCnpj = removeCharsDoc(cpfCnpj);
    if (
      (registerType === 'pf' &&
        newCpfCnpj.length === 11 &&
        validateCpfCnpj !== newCpfCnpj) ||
      (registerType === 'pj' &&
        newCpfCnpj.length === 14 &&
        validateCpfCnpj !== newCpfCnpj)
    ) {
      this.setState({ validateCpfCnpj: newCpfCnpj });
      checkCPFCNPJ(newCpfCnpj)
        .then(() => navigate('/bh', { state: { doc: newCpfCnpj } }))
        .catch(error => error);
    }
  };

  _handleLicensePlate = value => {
    const { validateLicensePlate } = this.state;
    const licensePlate = formatLicensePlate(
      value.replace('-', '').toUpperCase(),
    );
    if (licensePlate.length === 8 && validateLicensePlate !== licensePlate) {
      this.setState({ validateLicensePlate: licensePlate });
      this.props
        .getModel(licensePlate.replace(/[^a-zA-Z0-9]/g, ''))
        .then(response => {
          this.setState({ vehicle: response, licensePlate });
        })
        .catch();
    }
  };

  handleChangeCity = value => {
    const { changeDomain } = this.props;
    const domain = DOMAINS.filter(domain => domain.key === value)[0];
    changeDomain(domain);
  };

  handleOptionChange = changeEvent => {
    this.setState({ registerType: changeEvent.target.value });
  };

  handleSubmit = values => {
    const { vehicle } = this.state;
    const { register, city } = this.props;
    if (values.password !== values.confirmationPass) {
      toast.error('As Senhas digitadas não conferem.');
    } else {
      const objRegister =
        city.key === 'salvador'
          ? {
              name: values.name,
              email: values.email,
              password: values.password,
              ...{ mobile: removeCharsDoc(values.mobile) },
              ...{ doc: removeCharsDoc(values.doc) },
              vehicle: {
                licensePlate: values.board.replace('-', ''),
                type: 1,
                model: vehicle,
              },
            }
          : {
              name: values.name,
              email: values.email,
              password: values.password,
              ...{ mobile: removeCharsDoc(values.mobile) },
              ...{ doc: removeCharsDoc(values.doc) },
            };

      register(objRegister)
        .then(response => {
          const { action } = response;
          if ('login' === action) {
            navigate('/bh', { state: { doc: values.doc } });
          } else {
            navigate('/');
          }
        })
        .catch(err => err);
    }
  };

  render() {
    const {
      loadingRegister,
      loadingCheckCPF,
      loadingModelVehicle,
      city,
    } = this.props;
    return (
      <>
        {(loadingRegister || loadingCheckCPF || loadingModelVehicle) && (
          <Splash />
        )}
        <Formik
          initialValues={{
            city: city ? city.key : 'belohorizonte',
            doc: '',
            board: '',
            name: '',
            email: '',
            mobile: '',
            password: '',
            confirmationPass: '',
          }}
          validationSchema={this.handleValidation}
          onSubmit={this.handleSubmit}
        >
          {({ errors, touched, values }) => (
            <Form className="register-form">
              <h1>Cadastre-se</h1>
              <h5>
                ou
                <Link to="/bh">acesse sua conta</Link>
              </h5>
              <br />
              <div className="form-register">
                <div className="hidden">
                  <Field
                    component="select"
                    name="city"
                    validate={this.handleChangeCity}
                  >
                    <option value="belohorizonte" selected>
                      Belo Horizonte
                    </option>
                  </Field>
                </div>
                <div className="form-group">
                  <div className="radios">
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          value="pf"
                          checked={this.state.registerType === 'pf'}
                          onChange={this.handleOptionChange}
                        />
                        Pessoa física
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        <input
                          type="radio"
                          value="pj"
                          checked={this.state.registerType === 'pj'}
                          onChange={this.handleOptionChange}
                        />
                        Pessoa jurídica
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <Field
                    type="number"
                    name="doc"
                    validate={this.validateCpfCnpjExists}
                    render={({ field }) => (
                      <InputMask
                        maskChar={false}
                        mask={
                          this.state.registerType === 'pf'
                            ? '999.999.999-99'
                            : '99.999.999/9999-99'
                        }
                        placeholder={
                          this.state.registerType === 'pf' ? 'CPF' : 'CNPJ'
                        }
                        {...field}
                      />
                    )}
                  />
                  {errors.doc && touched.doc ? (
                    <ErrorsMessage message={errors.doc} />
                  ) : null}
                </div>
                <div className="form-group">
                  <Field
                    type="text"
                    name="name"
                    placeholder={
                      this.state.registerType === 'pf'
                        ? 'NOME COMPLETO'
                        : 'RAZÃO SOCIAL'
                    }
                  />
                  {errors.name && touched.name ? (
                    <ErrorsMessage message={errors.name} />
                  ) : null}
                </div>
                {city.key === 'salvador' && (
                  <div className="form-group">
                    <Field
                      type="text"
                      name="board"
                      validate={this._handleLicensePlate}
                      render={({ field }) => (
                        <InputMask
                          maskChar={false}
                          mask="aaa-9999"
                          placeholder="PLACA"
                          {...field}
                        />
                      )}
                    />
                    {errors.board && touched.board ? (
                      <ErrorsMessage message={errors.board} />
                    ) : null}
                  </div>
                )}
                <div className="form-group">
                  <Field type="text" name="email" placeholder="E-MAIL" />
                  {errors.email && touched.email ? (
                    <ErrorsMessage message={errors.email} />
                  ) : null}
                </div>
                <div className="form-group">
                  <Field
                    type="number"
                    name="mobile"
                    render={({ field }) => (
                      <InputMask
                        maskChar={false}
                        mask="(99) 99999-9999"
                        placeholder="CELULAR"
                        {...field}
                      />
                    )}
                  />
                  {errors.mobile && touched.mobile ? (
                    <ErrorsMessage message={errors.mobile} />
                  ) : null}
                </div>
                <div className="form-group">
                  <Field type="password" name="password" placeholder="SENHA" />
                  {errors.password && touched.password ? (
                    <ErrorsMessage message={errors.password} />
                  ) : null}
                </div>
                <div className="form-group">
                  <Field
                    type="password"
                    name="confirmationPass"
                    placeholder="CONFIRMAR SENHA"
                  />
                  {errors.confirmationPass && touched.confirmationPass ? (
                    <ErrorsMessage message={errors.confirmationPass} />
                  ) : null}
                </div>
                <button type="submit" className="btn btn-green">
                  Cadastrar
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

const mapState = state => ({
  loadingRegister: state.loading.effects.user.createUserAsync,
  loadingCheckCPF: state.loading.effects.user.checkCPFExists,
  loadingModelVehicle: state.loading.effects.vehicles.getModelVehicleAsync,
  city: state.city,
});

const mapDispatch = dispatch => ({
  register: payload => dispatch.user.createUserAsync(payload),
  checkCPFCNPJ: doc => dispatch.user.checkCPFExists(doc),
  changeDomain: payload => dispatch.city.changeDomain(payload),
  getModel: payload => dispatch.vehicles.getModelVehicleAsync(payload),
});

export default connect(
  mapState,
  mapDispatch,
)(Cadastro);
