import React from 'react';
import LOGO from '../../assets/images/logo.png';
import './style.css';

const Splash = () => (
  <div className="splash">
    <div className="splash-logo">
      <img src={LOGO} alt="Logo Rotativo Digital" />
      <div className="loader" />
    </div>
  </div>
);

export default Splash;
