import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { navigate, Link } from '@reach/router';
import EditProfile from '../../pages/modal/EditarPerfil';
import logo from './../../assets/images/logo.png';
import logoUrbes from './../../assets/images/logo-urbes.png';
import logoZaUrbes from './../../assets/images/logo-za-urbes.png';
import logoItaperuna from './../../assets/images/logo-itaperuna.png';
import logoJanauba from './../../assets/images/logo-janauba.png';
import footer_web from './../../assets/images/footer_web.jpg';
import { DOMAINS } from '../../utils/constants';

const propTypes = {
  user: PropTypes.shape({}).isRequired,
};

class PageWrapper extends PureComponent {
  constructor(props) {
    super(props);
    const { city, changeDomain } = props;
    if (city) changeDomain(city);
  }

  state = {
    modalEditProfileIsVisible: false,
  };

  logout = () => {
    this.props.logout();
  };

  openModal = () => {
    this.setState({ modalEditProfileIsVisible: true });
  };

  closeModal = () => {
    this.setState({ modalEditProfileIsVisible: false });
  };

  handleModal = () => {
    this.setState(
      prevState => ({
        modalEditProfileIsVisible: !prevState.modalEditProfileIsVisible,
      }),
      () => navigate('/esqueceu-senha'),
    );
  };

  render() {
    const { modalEditProfileIsVisible } = this.state;
    const { user, city } = this.props;
    const pathname = window.location.pathname;
    const isUser = !!Object.keys(user).length && user.token;

    if (
      (pathname === '/sorocaba' || pathname === '/saae') &&
      city.name !== 'Sorocaba'
    ) {
      const domain = DOMAINS.filter(domain => domain.key === 'sorocaba')[0];
      this.props.changeDomain(domain);
    }

    return (
      <>
        <header
          style={!isUser && city.name === 'Sorocaba' ? { padding: 0 } : null}
        >
          <div className="content">
            {city.name !== 'Sorocaba' ? (
              <Link className="logo" to="/">
                <img
                  src={
                    city.name === 'Itaperuna'
                      ? logoItaperuna
                      : city.name === 'Janaúba'
                      ? logoJanauba
                      : logo
                  }
                  alt=""
                />
              </Link>
            ) : city.name === 'Sorocaba' ? (
              isUser ? (
                <Link className="logoUrbes" to="/">
                  <img src={logoUrbes} alt="" />
                </Link>
              ) : pathname === '/login' ? (
                <Link className="logoZaUrbes" to="/">
                  <img src={logoZaUrbes} alt="" />
                </Link>
              ) : (
                <></>
              )
            ) : (
              <Link
                className={pathname === '/login' ? 'logo-less' : 'logo'}
                to="/"
              />
            )}
            {isUser && (
              <div className="topo">
                <div className="sair" onClick={this.logout}>
                  <i className="fas fa-sign-out-alt" />
                  <span>SAIR</span>
                </div>
                <div className="cidade">
                  <i className="fas fa-city" />
                  <span>{city.name}</span>
                </div>
                <div onClick={this.openModal} className="usuario">
                  <i className="fas fa-user" />
                  <span>{user.name}</span>
                </div>
              </div>
            )}
          </div>
        </header>
        <main className={city && city.name === 'Salvador' ? 'city-footer' : ''}>
          <div className={pathname !== '/sorocaba' ? 'content' : ''}>
            {this.props.children}
          </div>
        </main>
        {city && city.key === 'salvador' && (
          <footer>
            <img src={footer_web} alt="footer-img" />
          </footer>
        )}
        {modalEditProfileIsVisible && (
          <EditProfile
            closeModal={this.closeModal}
            handleModal={this.handleModal}
          />
        )}
      </>
    );
  }
}

PageWrapper.propTypes = propTypes;

const mapStateToProps = state => ({
  user: state.user,
  city: state.city,
});

const mapDispatch = dispatch => ({
  changeDomain: payload => dispatch.city.changeDomain(payload),
  logout: () => dispatch.user.clearStores(),
});

export default connect(
  mapStateToProps,
  mapDispatch,
)(PageWrapper);
