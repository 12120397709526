import React, { PureComponent } from 'react';
import { Formik, Form, Field } from 'formik';
import { Link, navigate, redirectTo } from '@reach/router';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import { addAuthToken } from '../../repositories';
import { DOMAINS } from '../../utils/constants';
import { removeCharsDoc } from '../../utils/formatters';
import * as Yup from 'yup';

import ErrorsMessage from '../../components/ErrorsMessage';
import Splash from '../../components/Splash';

const SignupSchema = Yup.object().shape({
  doc: Yup.string().required('Favor informar o seu CPF/CNPJ'),
  password: Yup.string().required('Favor informar a sua senha'),
});

class Login extends PureComponent {
  constructor(props) {
    super(props);
    const {
      user,
      location: { state },
      domain,
      changeDomain,
    } = props;

    if (Object.keys(user).length && user.token) {
      addAuthToken(user.token);
      redirectTo('/');
    }

    if (domain) {
      const domainSelect = DOMAINS.filter(item => item.key === domain)[0];
      changeDomain(domainSelect);
    }

    this.state = {
      doc: state && state.doc ? state.doc : '',
      maskDoc: '999.999.999-999',
    };
  }

  handleChangeCity = value => {
    const { changeDomain } = this.props;
    const domain = DOMAINS.filter(domain => domain.key === value)[0];
    if (domain) changeDomain(domain);
  };

  handleDoc = event => {
    if (removeCharsDoc(event.value).length >= 12)
      this.setState({ maskDoc: '99.999.999/9999-99' });
    else this.setState({ maskDoc: '999.999.999-999' });
  };

  render() {
    const { loading, domain } = this.props;
    const { doc } = this.state;
    return (
      <>
        {loading && <Splash />}
        <Formik
          initialValues={{
            doc,
            password: '',
            city: domain ? domain : 'belohorizonte',
          }}
          validationSchema={SignupSchema}
          onSubmit={async values => {
            this.props
              .login(values)
              .then(() => navigate('/'))
              .catch(err => err);
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <h1>Acesse sua conta</h1>
              <h5>
                ou
                <Link to={domain ? 'cadastro' : '/cadastro'}>cadastre-se</Link>
              </h5>
              <br />
              {!domain && (
                <div className="custom-select">
                  <Field
                    component="select"
                    name="city"
                    validate={this.handleChangeCity}
                  >
                    {DOMAINS.map(
                      domain =>
                        domain.key !== 'sorocaba' && (
                          <option key={domain.key} value={domain.key}>
                            {domain.name}
                          </option>
                        ),
                    )}
                  </Field>
                </div>
              )}
              <div className="form-group">
                <Field
                  type="text"
                  name="doc"
                  render={({ field }) => (
                    <InputMask
                      maskChar={false}
                      mask={this.state.maskDoc}
                      placeholder="CPF/CNPJ"
                      onChange={this.handleDoc(field)}
                      {...field}
                    />
                  )}
                />
                {errors.doc && touched.doc ? (
                  <ErrorsMessage message={errors.doc} />
                ) : null}
              </div>
              <div className="form-group">
                <Field type="password" name="password" placeholder="SENHA" />
                {errors.password && touched.password ? (
                  <ErrorsMessage message={errors.password} />
                ) : null}
              </div>
              <button type="submit" className="btn btn-green">
                Entrar
              </button>
              <Link to={'/esqueceu-senha'} className="forgot-password">
                Esqueceu sua senha?
              </Link>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

const mapState = state => ({
  loading: state.loading.effects.user.signInAsync,
  user: state.user,
  city: state.city,
});

const mapDispatch = dispatch => ({
  login: payload => dispatch.user.signInAsync(payload),
  changeDomain: payload => dispatch.city.changeDomain(payload),
});

export default connect(
  mapState,
  mapDispatch,
)(Login);
