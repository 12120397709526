import { toast } from 'react-toastify';
import {
  login,
  cadastrarUsuario,
  editUser,
  checkCadastroExiste,
  changePassword,
  forgotPassword,
  getUser,
} from '../repositories';
import { removeCharsDoc } from '../utils/formatters';

export const user = {
  state: {},
  reducers: {
    signIn(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    clearStore() {
      return {};
    },
  },
  effects: dispatch => ({
    async signInAsync(payload) {
      const { doc, password } = payload;
      try {
        const response = await login(removeCharsDoc(doc), password);
        dispatch.user.signIn(response.data);
        return true;
        //return dispatch.cards.saveCards(response.data.cards);
      } catch (e) {
        throw e;
      }
    },
    async checkCPFExists(payload) {
      try {
        const { data: checkRegister } = await checkCadastroExiste(payload);
        if (checkRegister.action === 'login') {
          toast.error(
            'CPF/CNPJ já cadastrado, você somente precisa realizar o Login.',
          );
          return Promise.resolve(checkRegister);
        }
        throw new Error();
      } catch (e) {
        throw e;
      }
    },
    async createUserAsync(payload) {
      try {
        const { doc } = payload;
        const { data: checkRegister } = await checkCadastroExiste(doc);
        if (checkRegister.action === 'login') {
          toast.error('CPF/CNPJ já cadastrado favor realizar o Login.');
          return Promise.resolve(checkRegister);
        } else {
          const response = await cadastrarUsuario(payload);
          dispatch.user.signIn(response.data);
          if (response.status !== 200) {
            toast.error('Erro ao cadastrar usuário.');
          } else {
            return Promise.resolve(response);
          }
        }
      } catch (e) {
        throw e;
      }
    },
    async getUserAsync() {
      try {
        const response = await getUser();
        dispatch.cards.saveCards(response.data.cards);
        dispatch.user.signIn(response.data);
        return Promise.resolve(response);
      } catch (e) {
        throw e;
      }
    },
    async getUserAsyncInstance() {
      try {
        const response = await getUser();
        dispatch.cards.saveCards(response.data.cards);
        return Promise.resolve(response);
      } catch (e) {
        throw e;
      }
    },
    async editUserAsync(payload, rootState) {
      try {
        const response = await editUser(payload);
        if (response.status !== 200) {
          toast.error('Erro ao editar usuário.');
        } else {
          toast.success('Usuário alterado com sucesso.');
          dispatch.user.getUserAsync();
          return response;
        }
      } catch (e) {
        throw e;
      }
    },
    async changePasswordAsync(payload) {
      try {
        const response = await changePassword(payload);
        if (response.status !== 200) {
          toast.error('Erro ao alterar a senha do usuário');
        } else {
          toast.success('Senha alterada com sucesso.');
        }
      } catch (e) {
        throw e;
      }
    },
    async forgotPasswordAsync(payload) {
      try {
        const response = await forgotPassword(payload);
        toast.success(
          `Uma mensagem foi enviada para o seu e-mail: ${response.data.email}`,
        );
        return Promise.resolve({});
      } catch (e) {
        throw e;
      }
    },
    async refreshAll() {
      dispatch.vehicles.refreshVehiclesAsync();
      dispatch.orders.refreshOrders();
      dispatch.balance.getBalanceAsync();
      dispatch.activations.refreshActivations();
    },
    async refreshGetBalance() {
      dispatch.balance.getBalanceAsync();
    },
    clearStores() {
      return new Promise(resolve => {
        dispatch.user.clearStore();
        dispatch.vehicles.clearStore();
        dispatch.orders.clearStore();
        dispatch.order.clearStore();
        dispatch.balance.clearStore();
        dispatch.activations.clearStore();
        dispatch.cards.clearStore();
        resolve();
      });
    },
  }),
};
