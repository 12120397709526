import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import BalanceCard from '../BalanceCard';
import StoreCard from '../StoreCard';
import CardsCard from '../CardsCard';
import TypeVehicle from '../TypeVehicle';

class AccountBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showBox: false,
    };
  }

  componentDidMount() {
    this.props.getBalance();
  }

  pickCredits = quantity => {
    const { saveCredits, changeModalState } = this.props;
    //console.log('CREDITOS = ' + quantity);
    saveCredits(quantity);
    changeModalState();
    // this.setState(prevState => ({ showBox: !prevState.showBox }));
  };

  changeShowBox = () => {
    this.setState(prevState => ({ showBox: !prevState.showBox }));
  };

  setTypeCredit = type => {
    //console.log('EXECUTOU setTypeCredit');
    this.props.saveCreditType(type);
    this.setState(prevState => ({ showBox: !prevState.showBox }));
  };

  render() {
    const { cards, city, selectCard, balance, loadingBalance } = this.props;

    return (
      <div className="account-box">
        <BalanceCard loading={loadingBalance} balance={balance} />
        {city.name === 'Patos de Minas' ||
        city.name === 'Conselheiro Lafaiete' ||
        city.name === 'Igarapé' ||
        city.name === 'Itaperuna' ||
        city.name === 'João Monlevade' ? (
          this.state.showBox ? (
            <StoreCard
              pickCredits={this.pickCredits}
              changeShowBox={this.changeShowBox}
            />
          ) : (
            <TypeVehicle setTypeCredit={this.setTypeCredit} />
          )
        ) : (
          <StoreCard pickCredits={this.pickCredits} />
        )}
        <CardsCard cards={cards} city={city} selectCard={selectCard} />
      </div>
    );
  }
}

const mapState = state => ({
  balance: state.balance,
  loadingBalance: state.loading.effects.balance.getBalanceAsync,
  // creditType: state.order.products.vehicleType
});

const mapDispatch = dispatch => ({
  saveCredits: credits => dispatch.order.saveCredits(credits),
  saveCreditType: creditType => dispatch.order.saveCreditType(creditType),
  selectCard: card => dispatch.cards.selectCard(card),
  getBalance: () => dispatch.balance.getBalanceAsync(),
});

export default connect(
  mapState,
  mapDispatch,
)(AccountBox);
