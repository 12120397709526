import React from 'react';

const TypeVehicle = props => {
  const { setTypeCredit } = props;
  return (
    <div className="box-sorocaba">
      <div className="box-content">
        <h4>Selecione o tipo de crédito que deseja comprar:</h4>

        <div className="radios">
          <div className="radio">
            <input
              type="radio"
              onChange={() => setTypeCredit(1)}
              id="pagamento"
              name="car"
            />
            <label className="payment-type" htmlFor="pagamento">
              <i className="fas fa-car-side" />
              Para Carro
            </label>
          </div>
          <div className="radio">
            <input
              onChange={() => setTypeCredit(2)}
              type="radio"
              id="boleto"
              name="motorcycle"
            />
            <label className="payment-type" htmlFor="boleto">
              <i className="fas fa-motorcycle" />
              Para Moto
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TypeVehicle;
