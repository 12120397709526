import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { init } from '@rematch/core';
import { PersistGate } from 'redux-persist/lib/integration/react';
import createRematchPersist, { getPersistor } from '@rematch/persist';
import createLoadingPlugin from '@rematch/loading';
import models from './models';
import storageSession from 'redux-persist/lib/storage/session';
import Splash from './components/Splash';

const loading = createLoadingPlugin({
  whitelist: [
    'user/signInAsync',
    'user/createUserAsync',
    'user/editUserAsync',
    'user/checkCPFExists',
    'user/changePasswordAsync',
    'user/forgotPasswordAsync',
    'user/getUserAsync',
    'vehicles/getVehiclesAsync',
    'vehicles/refreshVehiclesAsync',
    'vehicles/createVehiclesAsync',
    'vehicles/deleteVehiclesAsync',
    'vehicles/getModelVehicleAsync',
    'order/postOrderAsync',
    'orders/getOrdersAsync',
    'orders/refreshOrders',
    'orders/getOrderAsync',
    'orders/deleteOrderAsync',
    'balance/getBalanceAsync',
    'activations/parkAsync',
    'activations/getPossibleActivationsAsync',
    'activations/getActivationsAsync',
    'activations/refreshActivations',
    'activations/getActivationAcync',
    'promotions/applyPromotionCodeAsync',
    'promotions/getPromotionsAsync',
    'promotions/refreshPromotionsAsync',
    'promotions/getInvitePromotionAsync',
  ],
});

const persistPlugin = createRematchPersist({
  whitelist: ['user', 'cards', 'city', 'promotions'],
  storage: storageSession,
  version: 1,
});

const store = init({
  models,
  plugins: [persistPlugin, loading],
});

const persistor = getPersistor();

ReactDOM.render(
  <PersistGate loading={<Splash />} persistor={persistor}>
    <Provider store={store}>
      <App />
    </Provider>
  </PersistGate>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
