import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import ErrorsMessage from '../../components/ErrorsMessage';
import { ClipLoader } from 'react-spinners';
import ButtonLoading from '../../components/ButtonLoading';
import { ModalConfirm } from './Confirm';
import { formatLicensePlate } from '../../utils/formatters';

const RegisterVehicle = Yup.object().shape({
  licensePlate: Yup.string().required('Favor informar a PLACA'),
  model: Yup.string().required('Favor informar o MODELO'),
  type: Yup.number().required('Favor informar o TIPO'),
});

class AdicionarVeiculo extends PureComponent {
  state = {
    modalVisible: false,
  };

  handlePlaca = (licensePlate, setFieldValue) => {
    const normalizedPlate = licensePlate
      .replace(/[^a-zA-Z0-9]/g, '')
      .toUpperCase();
    setFieldValue('licensePlate', normalizedPlate, true);
    if (normalizedPlate.length === 7) {
      this.props
        .getModel(normalizedPlate)
        .then(model => setFieldValue('model', model, true))
        .catch(err => err);
    }
  };

  handleTypeVehicle = (type, setFieldValue) => {
    var vType = 1;
    if (type === 'moto') vType = 2;
    if (type === 'motofrete') vType = 4;
    if (type === 'carga') vType = 5;

    setFieldValue('type', vType, true);
  };

  handleModalState = () => {
    this.setState(prevState => ({ modalVisible: !prevState.modalVisible }));
  };

  submit = () => {
    const { register, changeModalState } = this.props;
    const { licensePlate, model, type } = this.state;
    this.setState({ modalVisible: false }, () => {
      register({
        model,
        type,
        licensePlate: licensePlate.replace(/[^a-zA-Z0-9]/g, '').toUpperCase(),
      })
        .then(() => changeModalState())
        .catch(err => err);
    });
  };

  render() {
    const { modalVisible, licensePlate } = this.state;
    const { changeModalState, loadingModel, loading, city } = this.props;

    return (
      <div className="modal">
        <div className="modal-content">
          <i className="fas fa-window-close" onClick={changeModalState} />
          <h3>Cadastrar veículo</h3>
          <Formik
            initialValues={{
              licensePlate: '',
              model: '',
              type: 1,
            }}
            validationSchema={RegisterVehicle}
            onSubmit={values => {
              this.setState({
                ...values,
                modalVisible: true,
              });
            }}
          >
            {({ errors, touched, setFieldValue }) => (
              <Form>
                <div className="form-group">
                  <label>Selecione um tipo de veículo:</label>
                  <div className="radios">
                    <div className="radio">
                      <input
                        type="radio"
                        id="carro"
                        name="veiculo"
                        defaultChecked={true}
                        onClick={ev =>
                          this.handleTypeVehicle(ev.target.id, setFieldValue)
                        }
                      />
                      <label htmlFor="carro">
                        <i className="fas fa-car" />
                        Carro
                      </label>
                    </div>
                    {city.key === 'belohorizonte' && (
                      <div className="radio">
                        <input
                          type="radio"
                          id="motofrete"
                          name="veiculo"
                          onClick={ev =>
                            this.handleTypeVehicle(ev.target.id, setFieldValue)
                          }
                        />
                        <label htmlFor="motofrete">
                          <i className="fas fa-motorcycle" />
                          Moto Frete C/D
                        </label>
                      </div>
                    )}
                    {city.key === 'belohorizonte' && (
                      <div className="radio">
                        <input
                          type="radio"
                          id="carga"
                          name="veiculo"
                          onClick={ev =>
                            this.handleTypeVehicle(ev.target.id, setFieldValue)
                          }
                        />
                        <label htmlFor="carga">
                          <i className="fas fa-truck" />
                          Carga e Descarga
                        </label>
                      </div>
                    )}
                    {city.key === 'patosdeminas' ||
                      city.key === 'igarape' ||
                      (city.key === 'itaperuna' ||
                        (city.key === 'lafaiete' ||
                          (city.key === 'monlevade' && (
                            <div className="radio">
                              <input
                                type="radio"
                                id="moto"
                                name="veiculo"
                                onClick={ev =>
                                  this.handleTypeVehicle(
                                    ev.target.id,
                                    setFieldValue,
                                  )
                                }
                              />
                              <label htmlFor="moto">
                                <i className="fas fa-motorcycle" />
                                Moto
                              </label>
                            </div>
                          ))))}
                  </div>
                </div>
                <div
                  className="form-group"
                  style={{ alignItems: 'flex-start' }}
                >
                  <label>Placa</label>
                  <Field
                    type="text"
                    name="licensePlate"
                    validate={this.validatePlaca}
                    render={({ field }) => (
                      <>
                        <InputMask
                          {...field}
                          mask="aaa-9*99"
                          maskChar={false}
                          placeholder="Placa"
                          onChange={ev =>
                            this.handlePlaca(ev.target.value, setFieldValue)
                          }
                        />
                        <div className="spinner-input">
                          <ClipLoader
                            sizeUnit={'px'}
                            size={20}
                            color="#ffffff"
                            loading={loadingModel}
                          />
                        </div>
                      </>
                    )}
                  />
                  {errors.licensePlate && touched.licensePlate && (
                    <ErrorsMessage
                      style={{
                        position: 'relative',
                      }}
                      message={errors.licensePlate}
                    />
                  )}
                </div>
                <div
                  className="form-group"
                  style={{ alignItems: 'flex-start' }}
                >
                  <label>Modelo</label>
                  <Field type="text" name="model" placeholder="Modelo" />
                  {errors.model && touched.model && (
                    <ErrorsMessage
                      style={{
                        position: 'relative',
                      }}
                      message={errors.model}
                    />
                  )}
                </div>
                <div className="form-group">
                  <ButtonLoading
                    type="submit"
                    color="#67983d"
                    text="Gravar"
                    loading={loading}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {modalVisible && (
          <ModalConfirm
            cancel={this.handleModalState}
            confirm={this.submit}
            text={`Tem certeza que deseja registrar o veículo com a placa ${formatLicensePlate(
              licensePlate,
            )} ?`}
          />
        )}
      </div>
    );
  }
}

const mapState = state => ({
  loading: state.loading.effects.vehicles.createVehiclesAsync,
  loadingModel: state.loading.effects.vehicles.getModelVehicleAsync,
  city: state.city,
});

const mapDispatch = dispatch => ({
  register: payload => dispatch.vehicles.createVehiclesAsync(payload),
  getModel: licensePlate =>
    dispatch.vehicles.getModelVehicleAsync(licensePlate),
});

export default connect(
  mapState,
  mapDispatch,
)(AdicionarVeiculo);
