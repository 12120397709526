import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ModalConfirm } from './Confirm';
import ButtonLoading from '../../components/ButtonLoading';

class GerarBoleto extends PureComponent {
  state = {
    creditos: 0,
    valor: 'RS0,00',
    modalVisible: false,
  };

  componentDidMount() {
    const { products, city, creditType } = this.props;
    var credito = null;
    if (creditType === 2) {
      credito = city.credits2.find(
        item => item.creditos === products[0].quantity,
      );
    } else {
      credito = city.credits.find(
        item => item.creditos === products[0].quantity,
      );
    }
    this.setState({
      ...credito,
    });
  }

  componentDidUpdate(prevProps) {
    const { products, city, creditType } = this.props;
    if (prevProps.products !== products && products.length > 0) {
      var credito = null;
      if (creditType === 2) {
        credito = city.credits2.find(
          item => item.creditos === products[0].quantity,
        );
      } else {
        credito = city.credits.find(
          item => item.creditos === products[0].quantity,
        );
      }
      this.setState({
        ...credito,
      });
    }
  }

  _order = () => {
    const { orderBoleto, changeModalState } = this.props;
    this.handleAlert();
    orderBoleto()
      .then(() => {
        changeModalState();
      })
      .catch(err => err);
  };

  handleAlert = () => {
    this.setState(prevState => ({
      modalVisible: !prevState.modalVisible,
    }));
  };

  render() {
    const { creditos, valor, modalVisible } = this.state;
    const { changeModalState, loading, city, creditType } = this.props;

    return (
      <div className="modal">
        <div className="modal-content">
          <i className="fas fa-window-close" onClick={changeModalState} />
          <h3>Gerar Boleto</h3>
          {city.name !== 'Salvador' ? (
            <p className="upper">
              {`${creditos} ${creditos > 1 ? 'créditos' : 'crédito'}`} rotativo
              {creditType === 2 ? ' Moto' : ' Carro'} = {valor}
            </p>
          ) : (
            <p className="upper">{valor}</p>
          )}
          <p>
            Enviaremos para o seu e-mail as instruções para o pagamento do
            boleto.
          </p>
          {city.name !== 'Salvador' ? (
            <p>
              Após o pagamento a compensação dos créditos será realizada em uma
              conta no prazo de 1 a 3 dias úteis
            </p>
          ) : (
            <p>
              Após o pagamento a compensação do valor será realizada em uma
              conta no prazo de 1 a 3 dias úteis
            </p>
          )}

          <ButtonLoading
            type="button"
            color="#67983d"
            text="Gerar boleto"
            callback={this.handleAlert}
            loading={loading}
          />
        </div>
        {modalVisible &&
          (city.name !== 'Salvador' ? (
            <ModalConfirm
              cancel={this.handleAlert}
              confirm={this._order}
              text={`Tem certeza que deseja comprar ${valor} em créditos ?`}
            />
          ) : (
            <ModalConfirm
              cancel={this.handleAlert}
              confirm={this._order}
              text={`Tem certeza que deseja comprar ${valor} ?`}
            />
          ))}
      </div>
    );
  }
}

const mapState = state => ({
  products: state.order.products,
  loading: state.loading.effects.order.postOrderAsync,
  city: state.city,
  creditType: state.order.vehicleType.vehicleType,
});

const mapDispatch = dispatch => ({
  orderBoleto: () => dispatch.order.postOrderAsync(),
});

export default connect(
  mapState,
  mapDispatch,
)(GerarBoleto);
