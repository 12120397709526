import React, { PureComponent } from 'react';
import { CardSaldo, CardReals, CardRealsSorocaba } from '../CardSaldo';
import { connect } from 'react-redux';
import './style.css';

class BalanceCard extends PureComponent {
  handleRefreshBalance = () => {
    const { refreshGetBalance, refreshOrders } = this.props;
    refreshGetBalance();
    refreshOrders();
  };

  render() {
    const { balance, loading, city } = this.props;

    const rotativo =
      (balance &&
        balance.find(
          item => item.product && item.product.description === 'ROTATIVO',
        )) ||
      {};
    const price = rotativo && rotativo.product && rotativo.product.price;
    const bonus =
      (balance &&
        balance.find(
          item => item.product && item.product.description === 'BÔNUS',
        )) ||
      {};
    const rotativoCarro =
      (balance &&
        balance.find(item => item.product && item.product.vehicleType === 1)) ||
      {};
    const rotativoMoto =
      (balance &&
        balance.find(item => item.product && item.product.vehicleType === 2)) ||
      {};
    return (
      <>
        {city.name === 'Salvador' ? (
          <div className="box">
            <div className="container-header-title-refresh">
              <h4>Saldo atual</h4>
              <button
                onClick={this.handleRefreshBalance}
                className="container-refresh-btn"
              >
                <i className="fas fa-sync refresh-icon" />
              </button>
            </div>
            <div className="card-saldo-container">
              <CardReals
                loading={loading}
                title="ROTATIVO"
                value={balance[0] || 0}
              />
            </div>
          </div>
        ) : city.name === 'Sorocaba' ? (
          <div className="box-sorocaba">
            <div className="container-header-title-refresh">
              <h3>Saldo atual</h3>
            </div>
            <div className="card-saldo-sorocaba">
              <CardRealsSorocaba value={balance[0] || 0} />
            </div>
          </div>
        ) : city.name === 'Patos de Minas' ||
          city.name === 'Conselheiro Lafaiete' ? (
          <div className="box-sorocaba">
            <div className="container-header-title-refresh">
              <h3>Saldo atual</h3>
              <button
                onClick={this.handleRefreshBalance}
                className="container-refresh-btn"
              >
                <i className="fas fa-sync refresh-icon" />
              </button>
            </div>
            <div className="card-saldo-container top-margin">
              <CardSaldo
                loading={loading}
                title="CARRO"
                value={rotativoCarro.quantity || 0}
              />
              <CardSaldo
                loading={loading}
                title="MOTO"
                value={rotativoMoto.quantity || 0}
              />
            </div>
          </div>
        ) : city.name === 'Igarapé' ||
          city.name === 'Ouro Preto' ||
          city.name === 'Itaperuna' ||
          city.name === 'Divinópolis' ? (
          <div className="box">
            <div className="container-header-title-refresh">
              <h4>Saldo atual</h4>
              <button
                onClick={this.handleRefreshBalance}
                className="container-refresh-btn"
              >
                <i className="fas fa-sync refresh-icon" />
              </button>
            </div>
            <div className="card-saldo-container">
              <CardReals
                loading={loading}
                title="ROTATIVO"
                value={rotativo.quantity * price || 0}
              />
            </div>
          </div>
        ) : (
          <div className="box-sorocaba">
            <div className="container-header-title-refresh">
              <h3>Saldo atual</h3>
              <button
                onClick={this.handleRefreshBalance}
                className="container-refresh-btn"
              >
                <i className="fas fa-sync refresh-icon" />
              </button>
            </div>
            <div className="card-saldo-container top-margin">
              <CardSaldo
                loading={loading}
                title="ROTATIVO"
                value={rotativo.quantity || 0}
              />
              <CardSaldo
                loading={loading}
                title="BÔNUS"
                value={bonus.quantity || 0}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapState = state => ({
  city: state.city,
  balance: state.balance,
});

const mapDispatch = dispatch => ({
  refreshGetBalance: () => dispatch.user.refreshGetBalance(),
  refreshOrders: () => dispatch.orders.refreshOrders(),
});

export default connect(
  mapState,
  mapDispatch,
)(BalanceCard);
