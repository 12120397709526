import React from 'react';
import { ClipLoader } from 'react-spinners';
import './style.css';

export default ({ loading, text, type, color, callback }) => (
  <button
    className="btn-loading"
    onClick={callback}
    style={{
      display: 'flex',
      width: 'auto',
      backgroundColor: color,
      textTransform: 'uppercase',
    }}
    type={type}
  >
    {text}
    <div className="spinner">
      <ClipLoader sizeUnit={'px'} size={20} color="#ffffff" loading={loading} />
    </div>
  </button>
);
