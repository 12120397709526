import { getTicketBalance, getDriverBalance } from '../repositories';
const initialState = [];

export const balance = {
  state: initialState,
  reducers: {
    saveBalance(state, payload) {
      return [...payload];
    },
    clearStore() {
      return initialState;
    },
  },
  effects: dispatch => ({
    async getBalanceAsync(payload, rootState) {
      try {
        if (rootState.city.key === 'salvador') {
          const response = await getDriverBalance();
          dispatch.balance.saveBalance([response.data.balance]);
        } else if (rootState.city.key === 'sorocaba') {
          const response = await getTicketBalance();
          let currentBalance =
            response.data[0].product.price * response.data[0].quantity;
          response.data.balance = currentBalance;
          dispatch.balance.saveBalance([response.data.balance]);
        } else {
          const response = await getTicketBalance();
          dispatch.balance.saveBalance(response.data);
        }
      } catch (err) {}
    },
  }),
};
