import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import { Router } from '@reach/router';

import PageWrapper from './components/PageWrapper';
import Login from './pages/Login';
import LoginUrbes from './pages/LoginUrbes';
import LoginBh from './pages/LoginBh';
import LoginSalvador from './pages/LoginSalvador';

import ForgotPassword from './pages/ForgotPassword';
import ForgotPasswordUrbes from './pages/ForgotPasswordUrbes';
import Register from './pages/Register';
import RegisterUrbes from './pages/RegisterUrbes';
import RegisterSalvador from './pages/RegisterSalvador';
import RegisterBh from './pages/RegisterBh';
import Home from './pages/Home';
import PrivateRoute from './hoc/PrivateRoute';
import 'react-toastify/dist/ReactToastify.css';
import './assets/style/main.css';
import LoginItaperuna from './pages/LoginItaperuna';
import RegisterItaperuna from './pages/RegisterItaperuna';
import LoginSAAE from './pages/LoginSAAE';

class App extends Component {
  render() {
    return (
      <PageWrapper>
        <Router style={{ width: '100%' }}>
          <Login path="/login" />
          <Login path="/patosdeminas" domain="patosdeminas" />
          <Login path="/igarape" domain="igarape" />
          <Login path="/lafaiete" domain="lafaiete" />
          <Login path="/monlevade" domain="monlevade" />
          <Login path="/passos" domain="passos" />
          <Login path="/vespasiano" domain="vespasiano" />
          <Login path="/itarare" domain="itarare" />
          <Login path="/neves" domain="neves" />
          <Login path="/capaobonito" domain="capaobonito" />
          <Login path="/divinopolis" domain="divinopolis" />
          <Login path="/janauba" domain="janauba" />
          <LoginUrbes path="/sorocaba" />
          <LoginSAAE path="/saae" />
          <LoginItaperuna path="/itaperuna" />
          <LoginBh path="/bh" />
          <LoginBh path="/belohorizonte" />
          <LoginSalvador path="/salvador" />
          <ForgotPassword path="/esqueceu-senha" />
          <ForgotPasswordUrbes path="/esqueceu-senha-urbes" />
          <Register path="/cadastro" />
          <RegisterUrbes path="/sorocaba/cadastro" />
          <PrivateRoute path="/" Component={Home} />
          <RegisterSalvador path="/salvador/cadastro" />
          <RegisterItaperuna path="/itaperuna/cadastro" />
          <RegisterBh path="/bh/cadastro/" />
          <Register path="/neves/cadastro" domain="neves" />
          <Register path="/patosdeminas/cadastro" domain="patosdeminas" />
          <Register path="/igarape/cadastro" domain="igarape" />
          <Register path="/lafaiete/cadastro" domain="lafaiete" />
          <Register path="/monlevade/cadastro" domain="monlevade" />
          <Register path="/passos/cadastro" domain="passos" />
          <Register path="/vespasiano/cadastro" domain="vespasiano" />
          <Register path="/itarare/cadastro" domain="itarare" />
          <Register path="/capaobonito/cadastro" domain="capaobonito" />
          <Register path="/divinopolis/cadastro" domain="divinopolis" />
          <Register path="/janauba/cadastro" domain="janauba" />
        </Router>
        <ToastContainer
          position="top-right"
          autoClose={7000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </PageWrapper>
    );
  }
}

export default App;
