import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';

import ErrorsMessage from '../../components/ErrorsMessage';
import ButtonLoading from '../../components/ButtonLoading';
import { normalizeCpfCnpj } from '../../utils/formatters';

const SignupSchema = Yup.object().shape({
  name: Yup.string().required('Favor informar o NOME'),
  mobile: Yup.string().required('Favor informar o Celular'),
  email: Yup.string()
    .email('E-MAIL inválido')
    .required('Favor informar o E-MAIL'),
});

class EditProfile extends PureComponent {
  render() {
    const { closeModal, handleModal, user, editUser, loading } = this.props;
    return (
      <div className="modal">
        <div className="modal-content">
          <i onClick={closeModal} className="fas fa-window-close" />
          <h3>Editar Perfil</h3>
          <Formik
            initialValues={{
              name: user.name,
              email: user.email,
              mobile: user.mobile,
            }}
            validationSchema={SignupSchema}
            onSubmit={values => {
              editUser({
                ...values,
                ...{ mobile: values.mobile.replace(/[^a-zA-Z0-9]/g, '') },
              }).then(() => closeModal());
            }}
          >
            {({ errors, touched }) => (
              <Form className="form-edit-profile">
                <div className="form-group">
                  <label htmlFor="doc">
                    {user.type === 'J' ? 'CNPJ' : 'CPF'}
                  </label>
                  <div className="doc">{normalizeCpfCnpj(user.doc)}</div>
                </div>
                <div className="form-group">
                  <label htmlFor="name">
                    {user.type === 'J' ? 'Razão Social' : 'Nome'}
                  </label>
                  <Field type="text" name="name" />
                  {errors.name && touched.name ? (
                    <ErrorsMessage message={errors.name} />
                  ) : null}
                </div>
                <div className="form-group">
                  <label htmlFor="email">E-mail</label>
                  <Field type="text" name="email" />
                  {errors.email && touched.email ? (
                    <ErrorsMessage message={errors.email} />
                  ) : null}
                </div>
                <div className="form-group">
                  <label htmlFor="mobile">CELULAR</label>
                  <Field
                    type="number"
                    name="mobile"
                    render={({ field }) => (
                      <InputMask
                        maskChar={false}
                        mask="(99) 99999-9999"
                        {...field}
                      />
                    )}
                  />
                  {errors.mobile && touched.mobile ? (
                    <ErrorsMessage message={errors.mobile} />
                  ) : null}
                </div>
                <div className="form-group buttons">
                  <button
                    type="button"
                    onClick={handleModal}
                    className="btn-form btn btn-yellow"
                  >
                    Alterar senha
                  </button>
                  <ButtonLoading
                    type="submit"
                    color="#67983d"
                    text="Salvar"
                    loading={loading}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  user: state.user,
  loading: state.loading.effects.user.editUserAsync,
});

const mapDispatch = dispatch => ({
  logout: () => dispatch.user.clearStores(),
  editUser: payload => dispatch.user.editUserAsync(payload),
});

export default connect(
  mapState,
  mapDispatch,
)(EditProfile);
