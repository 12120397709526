import moment from 'moment';

export const normalizeCpfCnpj = doc => {
  if (doc.length === 14) {
    const x = doc
      .replace(/\D/g, '')
      .match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);

    return !x[2]
      ? x[1]
      : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + '.' + x[5];
  } else {
    const x = doc
      .replace(/\D/g, '')
      .match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);

    return !x[2]
      ? x[1]
      : x[1] +
          '.' +
          (!x[3] ? x[2] : x[2] + '.') +
          x[3] +
          (x[4] ? '-' + x[4] : '');
  }
};

export const normalizePhone = (value, previousValue) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 2) {
      return `(${onlyNums}) `;
    }
    if (onlyNums.length === 7) {
      return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2)}`;
    }
  }
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 7) {
    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2)}`;
  }
  if (onlyNums.length === 10) {
    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 6)}-${onlyNums.slice(
      6,
      10,
    )}`;
  }
  return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}-${onlyNums.slice(
    7,
    11,
  )}`;
};

export const removeCharsDoc = doc => doc.replace(/[^a-zA-Z0-9]/g, '');

export const removeAcentos = text => {
  if (text) return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const normalizeBoard = board => {
  const x = board.replace(/\D/g, '').match(/(\d{0,3})(\d{0,4})/);
  return !x[2] ? x[1] : x[2];
};

export const formatNumberToCurrency = value =>
  value && value.toFixed(2).replace('.', ',');

export const formatTimestampToDateHours = timestamp => {
  return timestamp ? moment(timestamp).format('DD/MM/YYYY [às] HH:mm') : '';
};

export const formatTimestampToDate = timestamp => {
  return timestamp ? moment(timestamp).format('DD/MM/YYYY') : '';
};

export const formatLicensePlate = value => {
  return value ? value.substring(0, 3) + '-' + value.substring(3) : '';
};
