import {
  createVehicles,
  getVehicles,
  getModelVehicle,
  deleteVehicles,
} from '../repositories';
import { toast } from 'react-toastify';

export const vehicles = {
  state: [],
  reducers: {
    getVehicles(state, payload) {
      return [...payload];
    },
    clearStore() {
      return [];
    },
  },
  effects: dispatch => ({
    async getModelVehicleAsync(payload) {
      try {
        const response = await getModelVehicle(payload);
        const data =
          response.data.model !== 'undefined' ? response.data.model : null;
        return data;
      } catch (e) {}
    },
    async getVehiclesAsync(payload, rootState) {
      try {
        if (rootState.vehicles.length) {
          return Promise.resolve();
        }
        const response = await getVehicles();
        const data = response.data.driver ? response.data.driver.vehicles : [];
        return dispatch.vehicles.getVehicles(data);
      } catch (e) {
        throw e;
      }
    },
    async refreshVehiclesAsync(payload, rootState) {
      try {
        const response = await getVehicles();
        const data = response.data.driver ? response.data.driver.vehicles : [];
        return dispatch.vehicles.getVehicles(data);
      } catch (e) {
        throw e;
      }
    },
    async createVehiclesAsync(payload) {
      try {
        const response = await createVehicles(payload);
        if (response.status !== 200) {
          // DropDownHolder.alert("error", "Ops :(", "Erro ao cadastrar veículo.");
        } else {
          toast.success('Veículo criado com sucesso.');
          dispatch.vehicles.refreshVehiclesAsync();
          return Promise.resolve();
        }
      } catch (e) {
        throw e;
      }
    },
    async deleteVehiclesAsync(payload) {
      try {
        const response = await deleteVehicles(payload);
        if (response.status !== 200) {
        } else {
          toast.success('Veículo removido com sucesso.');
          dispatch.vehicles.refreshVehiclesAsync();
          return Promise.resolve();
        }
      } catch (e) {
        throw e;
      }
    },
  }),
};
