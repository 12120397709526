import {
  applyPromotionCode,
  getPromotions,
  getInvitePromotion,
} from '../repositories';
import { toast } from 'react-toastify';

const initialState = [];
export const promotions = {
  state: initialState,
  reducers: {
    getPromotions(state, payload) {
      return [...payload];
    },
    getInvitePromotion(state, payload) {
      return {
        ...payload,
      };
    },
    clearStore() {
      return [];
    },
  },
  effects: dispatch => ({
    async refreshPromotionsAsync(payload, rootState) {
      try {
        const response = await getPromotions();
        const data = response.data ? response.data : [];
        return dispatch.promotions.getPromotions(data);
      } catch (e) {
        throw e;
      }
    },
    async getPromotionsAsync(payload, rootState) {
      try {
        const response = await getPromotions();
        const data = response.data ? response.data : [];
        return dispatch.promotions.getPromotions(data);
      } catch (e) {
        throw e;
      }
    },
    async getInvitePromotionAsync(payload, rootState) {
      try {
        const response = await getInvitePromotion();
        const data = response.data ? response.data : [];
        return dispatch.promotions.getInvitePromotion(data);
      } catch (e) {
        throw e;
      }
    },
    async applyPromotionCodeAsync(payload) {
      try {
        const response = await applyPromotionCode(payload);
        if (response.status !== 200) {
          toast.error('Ops :( Erro ao cadastrar código promocional');
          return Promise.reject(response);
        } else {
          toast.success('Código aplicado com sucesso.');
          return Promise.resolve(response);
        }
      } catch (e) {
        if (e.response !== undefined) {
          toast.error(e.response.data.message);
        } else {
          toast.error('Ops :( Erro ao cadastrar código promocional');
        }
        return Promise.reject(e);
      }
    },
  }),
};
