import React from 'react';
import { connect } from 'react-redux';

const StoreCard = props => {
  const { pickCredits, city, creditType, changeShowBox } = props;
  var credits = null;
  if (creditType === 2) {
    credits = city.credits2;
  } else {
    credits = city.credits;
  }

  return (
    <div className="box-sorocaba">
      <div className="container-header-title-refresh">
        <h3>Comprar</h3>
        <button
          onClick={() => changeShowBox()}
          className={
            city.name !== 'Patos de Minas' ||
            city.name !== 'Igarapé' ||
            city.name !== 'Itaperuna'
              ? 'hidden'
              : 'container-refresh-btn'
          }
        >
          <i className="fas fa-sync refresh-icon" />
        </button>
      </div>
      <div className="scroll-value">
        {credits.map((i, index) => (
          <div
            onClick={() => pickCredits(i.creditos)}
            type="button"
            key={index}
            className="valor"
            alt={i.creditos}
          >
            {i.valor}
          </div>
        ))}
      </div>
    </div>
  );
};

const mapState = state => ({
  city: state.city,
  creditType: state.order.vehicleType.vehicleType,
});

export default connect(mapState)(StoreCard);
