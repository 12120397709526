import React from 'react';
import GerarBoleto from '../modal/GerarBoleto';
import DadosCartaoCredito from '../modal/DadosCartaoCredito';

const PaymentType = props => {
  const { paymentType, changeModalState } = props;
  return paymentType.method === 'BOLETO' ? (
    <GerarBoleto changeModalState={changeModalState} />
  ) : (
    <DadosCartaoCredito changeModalState={changeModalState} />
  );
};

export default PaymentType;
