import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  formatNumberToCurrency,
  formatTimestampToDateHours,
} from '../../../utils/formatters';
import './style.css';
import { ClipLoader } from 'react-spinners';

class DetalhesCompra extends PureComponent {
  render() {
    const {
      loading,
      changeModalState,
      order,
      order: { value, createdAt, status, referenceCode },
    } = this.props;
    let productOrder = order.products;
    var vehicleType = null;
    if (productOrder !== undefined) {
      productOrder.map(elem => (vehicleType = elem.vehicleType));
      //console.log(vehicleType);
    }
    const method =
      Object.keys(order).length > 0 &&
      order.payments.length > 0 &&
      order.payments[0].method;
    const url = method === 'Boleto Bancário' && order.payments[0].billet.url;
    // const isCancellable =
    //   order.action === 'CHARGEBACK' || order.action === 'CANCEL';
    const billet = method === 'Boleto Bancário' && order.payments[0].billet;
    return (
      <div className="modal">
        <div className="modal-content">
          <i className="fas fa-window-close" onClick={changeModalState} />
          <h3>Detalhe de compra</h3>
          {loading ? (
            <ClipLoader
              sizeUnit={'px'}
              size={30}
              color="#ffffff"
              loading={loading}
            />
          ) : (
            <div className="container-compra">
              <p>Valor:</p>
              <p>R$ {formatNumberToCurrency(value)}</p>
              <p>Tipo veículo:</p>
              <p>{vehicleType === 2 ? 'Moto' : 'Carro'}</p>
              <p>Data da compra:</p>
              <p>{formatTimestampToDateHours(createdAt)}</p>
              <p>Meio de pagamento:</p>
              <p>{method}</p>
              {url && (
                <>
                  <p>Data de Vencimento:</p>
                  <p>{formatTimestampToDateHours(billet.expirationDate)}</p>
                  <p>Linha Digitável (segure pressionando para copiar)</p>
                  <p>{billet.lineCode}</p>
                </>
              )}
              <p>Status pagamento:</p>
              <p>{status}</p>
              {referenceCode && (
                <>
                  <p>Autenticação:</p>
                  <p>{referenceCode}</p>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  order: state.orders.selectedOrder,
  loading: state.loading.effects.orders.getOrderAsync,
});

export default connect(mapState)(DetalhesCompra);
