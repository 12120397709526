import React from 'react';
import {
  formatTimestampToDateHours,
  formatNumberToCurrency,
} from '../../utils/formatters';
import './style.css';

export default function({ createdAt, valueTotal, status, id, callback }) {
  return (
    <div className="item-order" onClick={() => callback(id)}>
      <div className="left">
        <p>Valor total:</p>
        <p>{`R$ ${formatNumberToCurrency(valueTotal)}`}</p>
        <div style={{ marginTop: 5 }}>
          <p>Data da compra:</p>
          <p>{formatTimestampToDateHours(createdAt)}</p>
        </div>
        <div style={{ marginTop: 5 }}>
          <p>Status da compra:</p>
          <p>{status}</p>
        </div>
      </div>
      <div className="right">
        <i className="fas fa-dollar-sign" />
        {/* <div>
          <p>DETALHES</p>
        </div> */}
      </div>
    </div>
  );
}
