import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {} from 'formik';
import playstore from './../../assets/images/play-store.png';
import appstore from './../../assets/images/app-store.png';

class BaixeAplicativo extends PureComponent {
  state = {
    modalVisible: false,
  };

  handleModalState = () => {
    this.setState(prevState => ({ modalVisible: !prevState.modalVisible }));
  };

  submit = () => {
    window.location = 'http://rotativodigitalbh.com.br/app';
  };

  render() {
    const { changeModalState } = this.props;
    return (
      <div className="modal">
        <div className="modal-content">
          <i className="fas fa-window-close" onClick={changeModalState} />
          <h3>Baixe agora nosso aplicativo!</h3>
          <label className="label-app">
            Faça uma compra de 2 créditos e ganhe imediatamente 1 crédito
            rotativo!
          </label>
          <div className="modal-footer">
            <img alt="playstore" src={playstore} width="100" />
            <img alt="applestore" src={appstore} width="100" />
          </div>
          <button type="button" onClick={this.submit} className="btn btn-green">
            Baixar agora!
          </button>
        </div>
      </div>
    );
  }
}

const mapState = state => ({});

const mapDispatch = dispatch => ({});

export default connect(
  mapState,
  mapDispatch,
)(BaixeAplicativo);
