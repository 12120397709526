import { user } from './user';
import { vehicles } from './vehicles';
import { order } from './order';
import { cards } from './cards';
import { orders } from './orders';
import { balance } from './balance';
import { activations } from './activations';
import { city } from './city';
import { promotions } from './promotions';

export default {
  user,
  vehicles,
  order,
  cards,
  orders,
  balance,
  activations,
  city,
  promotions,
};
