import React from 'react';
import {
  formatLicensePlate,
  formatTimestampToDateHours,
} from '../../utils/formatters';
import './style.css';

export default function({ licensePlate, activatedAt, id, callback }) {
  return (
    <div className="item-activation" onClick={() => callback(id)}>
      <div className="left">
        <p>Placa:</p>
        <p>{formatLicensePlate(licensePlate)}</p>
        <div style={{ marginTop: 5 }}>
          <p>Data da ativação:</p>
          <p>{formatTimestampToDateHours(activatedAt)}</p>
        </div>
      </div>
      <div className="right">
        <i className="fas fa-clock" />
      </div>
    </div>
  );
}
