import axios from 'axios';
import {
  BASE_PATH,
  LOGIN,
  REGISTER,
  MINUTE,
  TRANSACIONA,
  VOUCHER,
} from '../utils/constants';
import { toast } from 'react-toastify';
import { removeAcentos } from '../utils/formatters';

const instance = API => {
  const instance = axios.create({
    baseURL: BASE_PATH + API,
    timeout: MINUTE,
    headers: {
      AcceptVersion: '1.0.0',
    },
  });
  instance.interceptors.response.use(
    response => response,
    error => {
      if (error && error.response && error.response.data.message) {
        toast.error(error.response.data.message);
      }
      return Promise.reject(error);
    },
  );
  return instance;
};

const instanceLogin = API => {
  const instanceLogin = axios.create({
    baseURL: BASE_PATH + API,
    timeout: MINUTE,
    headers: {
      Authorization:
        'Jwt eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYjJiIiwid2hvIjoiYXBpY2FkYXN0cmEiLCJpYXQiOjE1Mzg2NjgzOTEsImV4cCI6NDY5MjI2ODM5MX0.DtrtCHbL85OYwYfqIebMixZkzronDkI9i6OY8XaCtK0',
      'Accept-Version': '1.3.0',
    },
  });
  instanceLogin.interceptors.response.use(
    response => response,
    error => {
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      }
      return Promise.reject(error);
    },
  );
  return instanceLogin;
};

const instanceCadastro = API => {
  const instanceCadastro = axios.create({
    baseURL: BASE_PATH + API,
    timeout: MINUTE,
    headers: {
      Authorization:
        'Jwt eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYjJiIiwid2hvIjoiYXBpY2FkYXN0cmEiLCJpYXQiOjE1Mzg2NjgzOTEsImV4cCI6NDY5MjI2ODM5MX0.DtrtCHbL85OYwYfqIebMixZkzronDkI9i6OY8XaCtK0',
      'Accept-Version': '1.4.0',
    },
  });
  instanceCadastro.interceptors.response.use(
    response => response,
    error => {
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      }
      return Promise.reject(error);
    },
  );
  return instanceCadastro;
};

export const addAuthToken = token =>
  (instance().defaults.headers.common['Authorization'] = `Jwt ${token}`);

export const addAuthDomain = domain => {
  instance().defaults.headers.common['Domain'] = removeAcentos(domain);
  instanceLogin().defaults.headers.common['Domain'] = removeAcentos(domain);
  instanceCadastro().defaults.headers.common['Domain'] = removeAcentos(domain);
};

export const login = (username, password) =>
  instanceLogin(LOGIN)
    .post('/driver/login', null, {
      params: {
        username,
        password,
      },
    })
    .then(response => response)
    .catch(e => {
      throw e;
    });

export const checkCadastroExiste = cpf =>
  instanceCadastro(REGISTER)
    .get(`/driver/check/${cpf}`)
    .then(response => {
      return response;
    })
    .catch(e => {
      throw e;
    });

export const cadastrarUsuario = params =>
  instanceCadastro(REGISTER)
    .post('/driver', { ...params })
    .then(response => {
      addAuthToken(response.data.token);
      return response;
    })
    .catch(e => {
      throw e;
    });

export const getUser = () =>
  instance(REGISTER)
    .get('/driver')
    .then(response => {
      return response;
    })
    .catch(e => {
      throw e;
    });

export const editUser = params =>
  instance(REGISTER)
    .put('/driver', params)
    .then(response => response)
    .catch(e => {
      throw e;
    });

export const changePassword = params =>
  instance(REGISTER)
    .post('/driver/changePassword', params)
    .then(response => response)
    .catch(e => {
      throw e;
    });

export const getModelVehicle = params =>
  instanceCadastro(REGISTER)
    .get(`/vehicle/${params}`)
    .then(response => response)
    .catch(e => {
      throw e;
    });

export const getVehicles = () =>
  instance(REGISTER)
    .get('/driver/vehicle')
    .then(response => response)
    .catch(e => {
      throw e;
    });

export const createVehicles = params =>
  instance(REGISTER)
    .post('/driver/vehicle', params)
    .then(response => response)
    .catch(e => {
      toast.error('Erro ao cadastrar veículo');
      throw e;
    });

export const deleteVehicles = params =>
  instance(REGISTER)
    .delete(`/driver/vehicle/${params}`)
    .then(response => response)
    .catch(e => {
      throw e;
    });

export const forgotPassword = cpf =>
  instanceCadastro(LOGIN)
    .post(`/driver/forgotPassword/${cpf}`)
    .then(response => response)
    .catch(e => {
      throw e;
    });

export const postOrder = data => instance(TRANSACIONA).post('/order', data);

const createFilters = (prefix, data) => {
  return data
    ? Object.keys(data)
        .map(key => data[key] && `${prefix}[${key}]=${data[key]}`)
        .join('&')
    : '';
};

const createParamsPark = data => {
  return Array.isArray(data) ? `[${data.join(',')}]` : data;
};

export const getOrders = (offset, limit, filters) => {
  return instance(TRANSACIONA).get(
    `/history/orders/${offset}/${limit}?${createFilters('filters', filters)}`,
  );
};

export const getOrder = id => instance(TRANSACIONA).get(`/order/${id}`);

export const deleteOrder = (id, value) =>
  instance(TRANSACIONA).delete(`/order/${id}?value=${value}`);

export const getTicketBalance = () =>
  instance(TRANSACIONA).get('/ticket/balance');

export const getDriverBalance = () =>
  instance(TRANSACIONA).get('/driver/balance');

export const getLicenscePlate = (licenscePlate, quantity) =>
  instance(TRANSACIONA).get(`/ticket/activate/${licenscePlate}`, {
    params: { quantity },
  });

export const getActivations = (offset, limit, filters) =>
  instance(TRANSACIONA).get(
    `/history/activations/${offset}/${limit}?${createFilters(
      'filters',
      filters,
    )}`,
  );

export const getActivation = id =>
  instance(TRANSACIONA).get(`/activation/${id}`);

export const park = (licenscePlate, ticketId, data) =>
  instance(TRANSACIONA).post(
    `/ticket/activate/${licenscePlate}/${createParamsPark(ticketId)}`,
    data,
  );

export const deleteCard = (gateway, id) =>
  instance(REGISTER).delete(`/driver/cards/${gateway}/${id}`);

export const applyPromotionCode = promotionCode =>
  instance(VOUCHER).post(`/reclaim/${promotionCode}`);

export const getPromotions = () => instance(VOUCHER).get(`/history/3`);

export const getInvitePromotion = () => instance(VOUCHER).get(`/invite`);
